import "babel-polyfill";
import {
    routes,
    groupRoutes,
    participant
} from './routes.js';

import Vue from 'vue/dist/vue';
import VueRouter from 'vue-router';
import Toasted from 'vue-toasted';
import GroupSchedule from './components/Groups/GroupSchedule.vue';
import 'vue-resize/dist/vue-resize.css';
import VueResize from 'vue-resize/dist/vue-resize';
import VueGoodTable from './components/Table.vue';
import VueProgressBar from 'vue-progressbar'
import VueQuillEditor from 'vue-quill-editor'
import axios from 'axios';

require('./bootstrap');
// Handle all imports here

window.Vue = Vue;
window.Vue.use(require('vue-moment'));
window.Vue.use(VueGoodTable);

window.Vue.use(VueProgressBar, {
    color: '#73a3ec',
    failedColor: '#dc6763',
    thickness: '3px',
    transition: {
        speed: '0.2s',
        opacity: '0.5s',
        termination: 100
    },
    autoRevert: true,
    location: 'top',
    inverse: false
})
window.Vue.use(Toasted, {
    theme: "outline",
    position: "bottom-left",
    duration: 4000
});
window.Vue.use(VueRouter)

function updateType(e) {
    var cardType = payform.parseCardType(e.target.value);
}

// -----------------------------------------------

var adminExists = document.getElementById("app");
var groupAdminExists = document.getElementById("groupapp");
var homepageExists = document.getElementById("homepage");

if (adminExists) {

    window.Vue.use(VueQuillEditor);

    // Router config for the primary admin admin
    const router = new VueRouter({
        history: true,
        routes // short for `routes: routes`
    })
    Vue.prototype.$admin = true;
    // start the application
    const app = new Vue({
        el: '#app',
        router,
        mounted() {
            this.$Progress.finish()
        },
        created() {

            // Start progress bar on page load
            this.$Progress.start()
            this.$router.beforeEach((to, from, next) => {
                if (to.meta.progress !== undefined) {
                    let meta = to.meta.progress
                    this.$Progress.parseMeta(meta)
                }
                this.$Progress.start()
                next()
            })
            this.$router.afterEach((to, from) => {
                this.$Progress.finish()
            })
        },
    }).$mount('#app')
} else if (groupAdminExists) {
    Vue.prototype.$groupadmin = true;
    Vue.prototype.$admin = false;
    const router = new VueRouter({
        history: true,
        routes: groupRoutes // short for `routes: routes`
    })
    // start the application
    const app = new Vue({
        el: '#groupapp',
        router,
    }).$mount('#groupapp')

} else if (homepageExists) {
    var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

    if (!isIE11) {
        var payform = require('payform');


        if (document.getElementById("scheduler")) {
            // Format input for card number entry
            if (document.getElementById('ccnum')) {
                payform.cardNumberInput(document.getElementById('ccnum'));
                document.getElementById('ccnum').addEventListener('input', updateType);
            }
            if (document.getElementById('expiry')) {
                payform.expiryInput(document.getElementById('expiry'));
            }
            if (document.getElementById('cvc')) {
                payform.cvcInput(document.getElementById('cvc'));
            }
        }
    }

    window.Vue.use(VueResize)

    const app = new Vue({
        el: '#scheduler',
        template: '<GroupSchedule v-if="loaded" :homepage="true" :group="currentUser.group"  :exclude="exclusion" :totalPaid="currentUser.total_paid"></GroupSchedule>',
        data() {
            return {
                currentUser: null,
                exclusion: [],
                loaded: false,
            }
        },
        created() {
            axios.get('/api/users/me').then((response) => {
                this.currentUser = response.data;

                // we know the total of the user - currentUser.total_paid
                axios.get('api/schedules/due/today').then((response) => {
                    // fetch schedules
                    let schedules = response.data;
                    let scheduleTotal = 0;

                    // for all the schedules that we are overdue on then we need to set the default payment amount?

                    for (let i = 0; i < schedules.length; i++) {
                        let schedule = schedules[i];
                        let scheduleAmountDue = parseFloat(schedule.amount_due);
                        // for each schedule

                        // add the total if it is less than the total paid
                        // anything that is less than the total paid
                        // is a schedule that is past and we have paid for
                        if ((scheduleTotal + scheduleAmountDue) <= this.currentUser.total_paid) {
                            this.exclusion.push(schedule.id);
                            scheduleTotal += scheduleAmountDue;
                        }

                    }
                    this.loaded = true;

                });
            })
        },
        components: {
            GroupSchedule,
        }
    })

    const payments = new Vue({
        el: '#payments',
        template: '<vue-good-table title="All Payments" :columns="columns" :rows="rows" :paginate="true" />',
        data() {
            return {
                currentUser: null,
                columns: [{
                    label: 'Date',
                    field: 'date',
                    type: 'date',
                    filterable: true,
                },
                    {
                        label: 'Amount',
                        field: 'amount',
                        type: 'number',
                        html: false,
                        filterable: true,
                    },
                    {
                        label: 'Fee',
                        field: 'fee',
                        filterable: true,
                    },
                    {
                        label: 'Method',
                        field: 'method',
                        filterable: true,
                    },
                ],
                rows: [],
            }
        },
        created() {
            axios.get('/api/users/me').then((response) => {
                this.currentUser = response.data;
                for (let i = 0; i < this.currentUser.payments.length; i++) {
                    let payment = this.currentUser.payments[i];
                    let type = "online";

                    if (payment.online == 0) {
                        type = "offline";
                    }

                    this.rows.push({
                        date: payment.date,
                        amount: payment.amount_paid,
                        fee: payment.fee,
                        status: payment.status,
                        method: type,
                    });
                }
            });
        },
        components: {
            VueGoodTable
        }
    })


}
require('select2');

jQuery(document).ready(function ($) {
    let countries = require('./countries.json');
    let states = require('./states.json');

    let state = `<fieldset class="field-textinput" id="state">
        <label for="profile-change-billingaddresspostcode">
            <span>State</span>
            <hr>
        </label>
        <input type="text" class="state" name="state" placeholder="State" id="state">
    </fieldset>`;

    if ($('#state-picker').length) {
        $('#state-picker').select2({
            data: states
        });
    }

    if ($('#country-picker').length) {
        $('#country-picker').select2({
            data: countries
        });
    }
})
