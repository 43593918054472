<template>
<div>
    <section class="section section-welcome-message">
        <div class="container">
            <div class="row">
                <div class="col-xs-12">
                    <h1 class="welcome-message">Welcome <span v-if="currentUser">{{ currentUser.first_name }}</span></h1>
                </div>
            </div>
        </div>
    </section>

    <section class="section">
        <div class="container">
            <div class="row">

                <div class="col-sm-12 col-block-5">
                    <router-link to="/">
                        <button class="dashboard-button active">
                            <i class="icon ion-speedometer"></i>
                            <p>Dashboard</p>
                        </button>
                    </router-link>
                </div>

                <div class="col-sm-6 col-block-5">
                    <router-link to="/events">
                        <button class="dashboard-button">
                            <i class="icon ion-calendar"></i>
                            <p>Parades</p>
                        </button>
                    </router-link>
                </div>

                <div class="col-sm-6 col-block-5">
                    <router-link to="/groups">
                        <button class="dashboard-button">
                            <i class="icon ion-ios-people"></i>
                            <p>Groups</p>
                        </button>
                    </router-link>
                </div>

                <div class="col-sm-6 col-block-5">
                    <router-link to="/payments">
                        <button class="dashboard-button">
                            <i class="icon ion-social-usd-outline"></i>
                            <p>Payments</p>
                        </button>
                    </router-link>
                </div>

                <div class="col-sm-6 col-block-5">
                    <router-link to="/pages">
                        <button class="dashboard-button">
                            <i class="icon ion-document"></i>
                            <p>Pages</p>
                        </button>
                    </router-link>
                </div>
            </div>
        </div>
    </section>


    <ParticipantList></ParticipantList>
    <!-- <section class="section-section-admin-actions">
        <div class="container">
            <div class="row">
                <div class="col-xs-12 col-md-11">
                    <div class="row"> -->
                        <!-- The primary actions for an admin -->
                        <!-- <div class="col-xs-12 col-sm-4">
                            <router-link to="/events" class="button-link scheme-plain">
                                Manage Events
                            </router-link>
                        </div>

                        <div class="col-xs-12 col-sm-4">
                            <router-link to="/groups" class="button-link scheme-plain">
                                Manage Groups
                            </router-link>
                        </div>

                        <div class="col-xs-12 col-sm-4">
                            <router-link to="/pages" class="button-link scheme-plain">
                                Manage Pages
                            </router-link>
                        </div> -->
                    <!-- </div>
                </div>
            </div>
        </div>
    </section> -->

    <!-- <section class="section section-timeline">
        <div class="container">
            <div class="row">
                <div class="col-xs-12">
                    <h2>SCHEDULE CONTAINER</h2>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12 col-md-11">
                    <GroupSchedule v-for="group in groups" :key="group.id" :group="group"></GroupSchedule>
                </div>
            </div>

        </div>
    </section> -->



</div>
</template>

<script>
import ParticipantList from './Users/ParticipantList.vue';
import GroupSchedule from './Groups/GroupSchedule.vue';

export default {
    created(){
        axios.get('/api/groups/').then((response) => {
            // handle success response
            this.groups = response.data;
        });

        axios.get('/api/users/me').then((response) => {
            this.currentUser = response.data;
        })
    },
    data(){
        return{
            groups:null,
            currentUser: null,
        }
    },
	components: {
		ParticipantList,
		GroupSchedule
	}
}
</script>
