<template>

<tr class="participant-row">

    <td class="name" v-if="viewing">{{ this.participant.first_name }}</td>
    <td v-else>
        <fieldset class="field-textinput">
            <input type="text" name="name-group" v-model="first_name">
        </fieldset>
    </td>

    <td class="last-name" v-if="viewing">{{ this.participant.last_name}}</td>
    <td v-else>
        <fieldset class="field-textinput">
            <input type="text" name="name-group" v-model="last_name">
        </fieldset>
    </td>

    <td class="email" v-if="viewing">
        <a href="mailto:mali@evolvedigital.co.uk">{{ this.participant.email }}</a>
    </td>
    <td v-else>
        <fieldset class="field-textinput">
            <input  name="name-group" v-model="email">
        </fieldset>
    </td>

    <td class="total-paid">${{ this.participant.total_paid }}</td>
    <td class="total-remaining">${{ totalRemaining }}</td>
    <td class="total-overdue" >$0</td>


    <td v-if="viewing"><button @click="edit" class="no-border"><i class="icon ion-edit"></i></button></td>
    <td v-else><button @click="save">SAVE</button></td>

    <td><button @click="del"  class="scheme-red no-border"><i class="icon ion-trash-a"></i></button></td>
</tr>
</template>

<script>
export default {
	props: ['participant','editCallback','deleteCallback'],
	data() {
		return {
			first_name: this.participant.first_name,
            last_name: this.participant.last_name,
            email: this.participant.email,
            id: this.participant.id,
			totalPaid: this.participant.totalPaid,
            totalRemaining:0,
			viewing: true,
		}
	},
	created() {
        // set the totalRemaining, if the participant has a group
        if(this.participant.group && this.participant.group[0] != undefined){
            this.totalRemaining = this.participant.group[0].fee;
        }
	},
	methods: {
		edit() {
			this.viewing = false;
		},
		save() {
			// console.log("trying to save");
			// try saving
			axios.put('/api/users/' + this.id, {
                first_name: this.first_name,
                last_name: this.last_name,
                email: this.email,
                participant: true,
			})
			.then((response) => {
                // set to viewing mode after submission is saved
    			this.viewing = true;
                this.$toasted.success("Success! Participant: " + this.first_name + " updated.").goAway(6000);

                // emit to the participantlist this an update has occurred
                this.$emit('editCallback',{
        			first_name: this.first_name,
                    last_name: this.last_name,
                    email: this.email,
                    id: this.id,
        			totalPaid: this.totalPaid,
        		})
			})
            .catch((error) => {
                if (error.response) {
                    // console.log(error.response.data + " " + error.response.status);

                    // Unprocessable entity is 422
                    if (error.response.status == 422) {

                        // validation failed
                        var obj = error.response.data;
                        for (var key in obj) {
                            // TODO PERHAPS MOVE THIS TO THE LARAVEL SIDE?
                            let displayKey = key.charAt(0).toUpperCase() + key.slice(1);
                            // replace underscores
                            displayKey = displayKey.replace(/_/g, " ");

                            this.$toasted.error(displayKey + " : " + obj[key]);
                        }

                    } else if (error.response.status == 405) {
                        // 405 is a method not allowed

                    }
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                } else {
                    // Something happened in setting up the request that triggered an Error
                }
            })
		},
        del(){
            this.$emit('deleteCallback', this.participant);
        }
	}
}
</script>
