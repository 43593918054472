<template>
<div>
	<section class="section section-welcome-message">
		<div class="container">
			<div class="row">
				<div class="col-xs-12 col-sm-9">
					<h1 class="welcome-message">New Participant</h1>
				</div>
                <div class="col-xs-12 col-sm-3">
                    <br>
                    <router-link to="/">
                        <button>Back</button>
                    </router-link>
                </div>
			</div>
		</div>
	</section>
	<section class="section section-new-group">
		<div class="container">
			<div class="row">
				<div class="col-xs-12 col-md-12">
					<div action="" class="form-NewGroup">
						<!-- Standard field input -->
						<fieldset class="field-textinput">
							<label for="name-group">
                                <span>First name</span>
                                <hr class="bg-white">
                            </label>
							<input type="text" name="name-group" v-model="first_name" placeholder="First Name">
						</fieldset>

                        <fieldset class="field-textinput">
                            <label for="name-group">
                                <span>Last name</span>
                                <hr class="bg-white">
                            </label>
                            <input type="text" name="name-group" v-model="last_name" placeholder="Last Name">
                        </fieldset>

                        <fieldset class="field-textinput">
                            <label for="name-group">
                                <span>E-mail</span>
                                <hr class="bg-white">
                            </label>
                            <input  name="name-group" v-model="email" placeholder="Email">
                        </fieldset>

                        <br><br>
                        <NSearch :type="'groups'" :placeholder="'Search for groups'" :model="'id'" :displays="['name']" @callback="onGroupSelect"></NSearch>

                    	<button class="button-link"  @click="save">SAVE</button>
					</div>
				</div>
			</div>
		</div>
	</section>
</div>

</template>

<script>
import NSearch from '../NSearch.vue';

export default {
	data() {
		return {
			first_name: "",
			last_name: "",
			email: "",
			password: this.generateRandomPassword(),
			group: 0,
		}
	},
	methods: {
		save() {
            this.$Progress.start();
			axios.post('/api/users/register', {
					first_name: this.first_name,
					last_name: this.last_name,
					email: this.email,
					password: this.password,
					group: this.group,
					participant: true,
				})
				.then((response) => {
                    this.$Progress.finish();
					this.$toasted.success("Success! Participant: " + this.first_name + " saved.").goAway(6000);


                    this.$router.go(-1);

					// reset the participant
					// this.participant = {
					// 	name: null,
					// 	email: null,
					// 	password: null,
					//     group:0,
					// };
					// this.creating = false;
					// this.participants = response.data;
				})
				.catch((error) => {
                    this.$Progress.fail();
					if (error.response) {
						// The request was made and the server responded with a status code
						// that falls out of the range of 2xx
						if (error.response.status == 422) {

							var obj = error.response.data;
							// console.log(obj);
							for (var key in obj) {
								// make first letter uppercase
								let displayKey = key.charAt(0).toUpperCase() + key.slice(1);
								// replace underscores
								displayKey = displayKey.replace(/_/g, " ");

								this.$toasted.error(displayKey + " : " + obj[key]);
							}

						} else if (error.response.status == 405) {
							// validation failed
						}
					} else if (error.request) {
						// The request was made but no response was received
						// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
						// http.ClientRequest in node.js
					} else {
						// Something happened in setting up the request that triggered an Error
					}
				})
		},
		onGroupSelect(selected) {
			this.group = selected.id;
		},
        generateRandomPassword() {
			return Math.random().toString(36).slice(-8);
		},
	},
	components: {
		NSearch,
	}
}
</script>
