<template>
<div class="search">
	<input type="text" v-model="search" v-bind:placeholder="placeholder">
</div>
</template>

<script>
export default {
	props: ['data','placeholder'],
	data() {
		return {
			search: "",
		}
	},
    created(){
        this.$emit('callback', this.filteredItems)
    },
	computed: {
		filteredItems() {
			var that = this;
			return this.data.filter(function(value) {
				return value.name.toLowerCase().indexOf(that.search.toLowerCase()) >= 0;
			})
		}
	},
	watch: {
		search: function(search) {
            this.$emit('callback', this.filteredItems)
		}
	},
}
</script>
