<template>
<div>
	<section class="section section-welcome-message">
		<div class="container">
			<div class="row">
				<div class="col-xs-12">
					<h1 class="welcome-message">Actions</h1>
				</div>
			</div>
		</div>
	</section>

	<section class="section">
		<div class="container">
			<div class="row">

				<div class="col-sm-12 col-block-5">
					<router-link to="/">
						<button class="dashboard-button">
                                <i class="icon ion-speedometer"></i>
                                <p>Dashboard</p>
                            </button>
					</router-link>
				</div>

				<div class="col-sm-6 col-block-5">
					<router-link to="/events">
						<button class="dashboard-button">
                                <i class="icon ion-calendar"></i>
                                <p>Parades</p>
                            </button>
					</router-link>
				</div>

				<div class="col-sm-6 col-block-5">
					<router-link to="/groups">
						<button class="dashboard-button">
                                <i class="icon ion-ios-people"></i>
                                <p>Groups</p>
                            </button>
					</router-link>
				</div>

				<div class="col-sm-6 col-block-5">
					<router-link to="/payments">
						<button class="dashboard-button active">
                                <i class="icon ion-social-usd-outline"></i>
                                <p>Payments</p>
                            </button>
					</router-link>
				</div>

				<div class="col-sm-6 col-block-5">
					<router-link to="/pages">
						<button class="dashboard-button">
                                <i class="icon ion-document"></i>
                                <p>Pages</p>
                            </button>
					</router-link>
				</div>
			</div>
		</div>
	</section>
	<section class="section section-participant-list">

		<div class="container">

            <div class="row">
                <div class="col-xs-12">

                    <ul>
                        <li v-if="actions != null && actions.length > 0" v-for="action in actions">
                            {{ action.title }}
                            <button @click.prevent.stop="undo(action.id)">UNDO</button>
                            <hr>
                        </li>
                    </ul>


                </div>


            </div>

		</div>
	</section>

</div>
</template>

<script>
export default {
	data() {
		return {
			actions: [],
		}
	},
	created() {
		this.$Progress.start();
		axios.get('/api/actions/').then((response) => {

            this.actions = response.data;
			this.$Progress.finish();

		});
	},
	methods: {
		undo(id) {
			this.$Progress.start();

			axios.post('/api/actions/' + id + '/undo').then((response) => {
                this.actions = response.data;
				this.$toasted.success("Success! Undo complete.").goAway(6000);

				// finally push to the rows
				this.$Progress.finish();

			}).catch((error) => {
				this.$Progress.fail();
				if (error.response) {
					// Unprocessable entity is 422
					if (error.response.status == 422) {
						// validation failed
						var obj = error.response.data;
						for (var key in obj) {

							let displayKey = key.charAt(0).toUpperCase() + key.slice(1);
							// replace underscores
							displayKey = displayKey.replace(/_/g, " ");

							this.$toasted.error(displayKey + " : " + obj[key]);
						}
					}
				} else {
					// console.log('Error', error.message);
				}
			})
		},
	},
}
</script>
