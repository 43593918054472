<template>
    <div>
        <section class="section section-welcome-message">
            <div class="container">
                <div class="row">
                    <div class="col-xs-12 col-sm-9">
                        <h1 class="welcome-message">Editing Group: {{ group.name }}</h1>
                    </div>
                    <div class="col-xs-12 col-sm-3">
                        <br>
                        <router-link to="/groups">
                            <button>Back</button>
                        </router-link>
                    </div>
                </div>
            </div>
        </section>
        <section class="section section-new-group">
            <div class="container">
                <div class="row">
                    <div class="col-xs-12 col-md-12">
                        <div class="form-NewGroup">
                            <fieldset class="field-textarea"  v-if="parade">
                                <label for="description-group">
                                    <span>Currently selected: {{ parade.title }}</span>
                                </label>
                                <button @click="changeEvent" class="no-border">Change Event</button>
                            </fieldset>

                            <div v-else>
                                <fieldset class="field-textarea">
                                    <label for="description-group">
                                        <span>Select Event: </span>
                                        <hr class="bg-white">
                                    </label>
                                </fieldset>
                                <NSearch :type="'events'" :placeholder="'Search for events'" :model="''" :displays="['title']" @callback="onEventSelect"></NSearch>
                            </div>


                            <!-- Standard field input -->
                            <fieldset class="field-textinput">
                                <label for="name-group">
                                    <span>Group Name</span>
                                    <hr class="bg-white">
                                </label>
                                <input type="text" name="name-group" v-model="group.name" placeholder="Name">
                            </fieldset>
                            <GroupOwner v-if="owner" :groupID="id" @callback="onOwnerSelect" :groupOwner="owner"></GroupOwner>


                            <!-- Textarea -->
                            <fieldset class="field-textarea">
                                <label for="description-group">
                                    <span>Group Description</span>
                                    <hr class="bg-white">
                                </label>
                                <textarea name="description-group" id="description-group" cols="30" rows="8"  v-model="group.description" placeholder="description"></textarea>
                            </fieldset>

                            <fieldset class="field-textinput">
                                <label for="name-group" class="currency">
                                    <span>Group Logo (320 x 200)</span>
                                    <hr class="bg-white">
                                </label>
                                <div v-if="!image">
                                    <label class="btn btn-default btn-file">
                                        Browse for image<input type="file" style="display: none;" @change="onImageChange">
                                    </label>
                                </div>
                                <div v-else>
                                    <img :src="image" style="max-width:320px; max-height: 200px;" />
                                    <button @click="changeImage" class="no-border">Change image</button>
                                </div>
                            </fieldset>

                            <fieldset class="field-textinput">
                                <label for="name-group" class="currency">
                                    <span>Groups Terms</span>
                                    <hr class="bg-white">
                                </label>
                                <div v-if="!file">
                                    <label class="btn btn-default btn-file">
                                        Upload Terms and Conditions<input type="file" style="display: none;" @change="onFileChange">
                                    </label>
                                </div>
                                <div v-else>
                                    <label class="btn btn-default btn-file" @click="changeFile">
                                        Change terms and conditions sheet
                                    </label>
                                </div>
                            </fieldset>
                            <br><br>


                            <!-- Input with a button next to it -->
                            <button class="button-link"  @click="save">
                                <span v-if="schedules == null || schedules.length == 0">
                                    Save and set schedules
                                </span>
                                <span v-else>
                                    Save changes
                                </span>
                            </button>


                        </div>
                    </div>
                </div>
            </div>
        </section>

    </div>
</template>

<script>
import NSearch from '../NSearch.vue';
import GroupOwner from './GroupOwner.vue';

export default {
    created(){
        let id = this.$route.params.id;
        axios.get('/api/groups/' + id).then((response) => {
            this.group = response.data;

            // once we have the group
            // check if there is an image or terms sheet already
            axios.get('/uploads/images/' + this.group.name + '.jpg').then((response) => {
                this.image = '/uploads/images/' + this.group.name + '.jpg';
            }).catch((error) => {
            });

            axios.get('/uploads/terms/' + this.group.name + '.pdf').then((response) => {
                this.file = '/uploads/terms/' + this.group.name + '.pdf';
            }).catch((error) => {
            });

            axios.get('/api/events/' + this.group.event_id).then((response) => {
                this.parade = response.data;
            })

            axios.get('/api/users/' + this.group.owner_id).then((response) => {
                this.owner = response.data;
            })

            // console.log("edit group " + this.owner);
        })
        axios.get('/api/groups/' + id + '/schedules').then((response) => {
            this.schedules = response.data;
        })
    },
    data(){
        return{
            id:this.$route.params.id,
            group:{
                name:"",
            },
            file: null,
            image: null,
            fileImage: null,
            owner:0,
            parade:null,
            schedules:null,
        }
    },
    methods: {
        save() {
            this.$Progress.start();
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }
            var data = new FormData();
            data.append('name', this.group.name);
            data.append('description', this.group.description);
            data.append('fee', this.group.fee);
            data.append('owner_id', this.group.owner_id);
            data.append('event_id', this.group.event_id);
            data.append('file', this.file);
            data.append('image', this.fileImage);

			// save the new participant
            axios.post('/api/groups/' + this.id, data,config)
			.then((response) => {
				// console.log("----------------");
				// console.log(response);
                this.$Progress.finish();
                this.$toasted.success("Success! Group: " + this.group.name + " updated.").goAway(6000);

                // if they don't have a schedule route them to create a new one
                if(this.schedules == null || this.schedules.length == 0){
                    this.$router.push({ path: '/groups/' + this.id + "/schedule" })
                }else{
                    this.$router.push({ path: '/groups/'})
                }

			})
            .catch((error) => {
                this.$Progress.fail();
                if (error.response) {
                    // console.log(error.response.data + " " + error.response.status);

                    // Unprocessable entity is 422
                    if (error.response.status == 422) {

                        // validation failed
                        var obj = error.response.data;
                        for (var key in obj) {
                            
                            let displayKey = key.charAt(0).toUpperCase() + key.slice(1);
                            // replace underscores
                            displayKey = displayKey.replace(/_/g, " ");

                            this.$toasted.error(displayKey + " : " + obj[key]);
                        }

                    } else if (error.response.status == 405) {
                        // 405 is a method not allowed

                    }
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    // console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    // console.log('Error', error.message);
                }
            })
		},
        registerData(){
            if(this.schedule == null){
                return {
                    name: this.group.name,
                    description: this.group.description,
                    owner_id: this.group.owner_id,
                    event_id: this.group.event_id,
                    fee: this.group.fee,
                }
            }else{
                return {
                    name: this.group.name,
                    description: this.group.description,
                    owner_id: this.group.owner_id,
                    event_id: this.group.event_id,
                }
            }
        },
        onEventSelect(selected){
            this.parade = selected;
            this.group.event_id = selected.id;
        },
        onOwnerSelect(selected){
            // console.log(selected);
            this.group.owner_id = selected;
        },
        changeEvent(){
            this.parade = null;
        },
        onImageChange(e) {
			var files = e.target.files || e.dataTransfer.files;
			if (!files.length)
				return;

			this.createImage(files[0]);
		},
		onFileChange(e) {
			var files = e.target.files || e.dataTransfer.files;
			if (!files.length)
				return;
			this.file = files[0];

			// I guess we could add something saying its changed

		},
		createImage(file) {
			var image = new Image();
			var reader = new FileReader();
			var vm = this;

			reader.onload = (e) => {
				vm.image = e.target.result;
			};
			reader.readAsDataURL(file);

            this.fileImage = file;

		},
		changeImage: function(e) {
			this.image = '';
		},
        changeFile: function(e) {
			this.file = '';
		}
    },
    components:{
        NSearch,
        GroupOwner
    }
}
</script>
