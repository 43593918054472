<template>
    <div>
        <section class="section section-welcome-message">
    		<div class="container">
    			<div class="row">
    				<div class="col-xs-12">
    					<h1 class="welcome-message">Managing Payments</h1>
    				</div>
    			</div>
    		</div>
    	</section>

        <section class="section">
            <div class="container">
                <div class="row">

                    <div class="col-sm-12 col-block-5">
                        <router-link to="/">
                            <button class="dashboard-button">
                                <i class="icon ion-speedometer"></i>
                                <p>Dashboard</p>
                            </button>
                        </router-link>
                    </div>

                    <div class="col-sm-6 col-block-5">
                        <router-link to="/events">
                            <button class="dashboard-button">
                                <i class="icon ion-calendar"></i>
                                <p>Parades</p>
                            </button>
                        </router-link>
                    </div>

                    <div class="col-sm-6 col-block-5">
                        <router-link to="/groups">
                            <button class="dashboard-button">
                                <i class="icon ion-ios-people"></i>
                                <p>Groups</p>
                            </button>
                        </router-link>
                    </div>

                    <div class="col-sm-6 col-block-5">
                        <router-link to="/payments">
                            <button class="dashboard-button active">
                                <i class="icon ion-social-usd-outline"></i>
                                <p>Payments</p>
                            </button>
                        </router-link>
                    </div>

                    <div class="col-sm-6 col-block-5">
                        <router-link to="/pages">
                            <button class="dashboard-button">
                                <i class="icon ion-document"></i>
                                <p>Pages</p>
                            </button>
                        </router-link>
                    </div>
                </div>
            </div>
        </section>
        <section class="section section-participant-list">

        	<div class="container">
        		<div class="row">
        			<div class="col-xs-12 col-md-12">
        				<div class="row options-row">
        					<div class="col-xs-12 col-sm-6">
        						<button class="no-border" @click="create">
                                    <span v-if="!creating">Create manual payment</span>
                                    <span v-else>Cancel create payment</span>
                                </button>
        					</div>
                            <div class="col-xs-12 col-sm-6">
                                <button class="no-border" @click="suplement">
                                    <span v-if="!suplementing">Supplement user total</span>
                                    <span v-else>Cancel supplement user total</span>
                                </button>
                            </div>
        				</div>
        			</div>
        		</div>

                <div class="row" v-if="suplementing">
                    <div class="col-xs-12">
                        <br><br>
        				<hr>
        				<p>
        				Suplementing a user's total
        				</p>
        				<hr>
        				<br><br>
                    </div>

                    <div class="col-xs-12 col-sm-7">
        				<p>Choose user:</p>
        				<NSearch :type="'users'" :placeholder="'Search for users'" :model="'id'" :displays="['id','first_name','last_name', 'email']" @callback="onOwnerSelect"></NSearch>
        				<hr>
        			</div>

                    <div class="col-xs-12 col-sm-5">
                        <fieldset class="field-textinput">
                            <label for="name-group" class="currency">
                                <span>Supplement total<vue-numeric currency="$" separator="," :minus="true" v-model="supplementTotal" read-only></vue-numeric></span>
                                <hr class="bg-white">
                            </label>
                            <vue-numeric currency="$" separator="," v-model="supplementTotal"  :minus="true" ></vue-numeric>
                        </fieldset>
                    </div>

                    <div class="col-xs-12">
                        <button @click="addSupplementToUser">Add supplement</button>
                        <br><br>
                    </div>
                </div>


        		<div class="row" v-if="creating">

        			<div class="col-xs-12">
        				<br><br>
        				<hr>
        				<p>
        					Adding a manual payment
        				</p>
        				<hr>
        				<br><br>
        			</div>

        			<div class="col-xs-12 col-sm-7">
        				<p>Choose user:</p>
        				<NSearch :type="'users'" :placeholder="'Search for users'" :model="'id'" :displays="['id','first_name','last_name', 'email']" @callback="onOwnerSelect"></NSearch>
        				<hr>
        			</div>


        			<div class="col-xs-12 col-sm-3">
        				<fieldset class="field-textinput">
        					<label for="name-group">
                                <span>Date</span>
                                <hr class="bg-white">
                            </label>
        					<input type="date" v-model="payment.date" />
        				</fieldset>
        			</div>

        			<div class="col-xs-12 col-sm-2">
        				<fieldset class="field-textinput">
        					<label for="name-group" class="currency">
                                <span>Amount Paid <vue-numeric currency="$" separator="," :minus="true" v-model="payment.cost" read-only></vue-numeric></span>
                                <hr class="bg-white">
                            </label>
        					<vue-numeric currency="$" separator="," v-model="payment.cost"  :minus="true" ></vue-numeric>
        				</fieldset>
        			</div>

        			<div class="col-xs-12 col-sm-5">
        				<!-- Textarea -->
        				<fieldset class="field-textarea">
        					<label for="description-group">
                                <span>Payment Reference</span>
                                <hr class="bg-white">
                            </label>
        					<textarea name="description-group" id="description-group" cols="30" rows="6" v-model="payment.ref" placeholder="Description"></textarea>
        				</fieldset>
        			</div>


        			<div class="col-xs-12">
        				<button @click="save">Save</button>
        				<br><br>
        			</div>
        		</div>



        		<vue-good-table v-if="loaded" title="Payments" :columns="columns" :rows="rows" :paginate="true" :actions="actions" @del="del"  :lineNumbers="true" :defaultFilters="columnFiltersDefault"/>
                <div v-else  style="margin:30px auto; text-align:center;">
                    <div class="mnml-spinner dark" style="margin:0 auto">
                    </div>
                    <p class="text-small" style="margin-top:20px;">Loading Payments...</p>
                </div>
        	</div>
        </section>

    </div>
</template>

<script>
import NSearch from '../NSearch.vue';
import VueNumeric from 'vue-numeric';
import VueGoodTable from '../Table.vue';
export default {
	props: ['fromGroup'],
	data() {
		return {
			columns: [
                {
                    label: "id",
                    field: "id",
                    type: 'number',
                    filterable: true,
                },
				{
					label: 'First Name',
					field: 'first_name',
					filterable: true,
				},
                {
                    label: 'Last Name',
                    field: 'last_name',
                    filterable: true,
                },
                {
                    label: 'Group',
                    field: 'group',
                    filterable: true,
                    editable: true,
                },
				{
					label: 'Date',
					field: 'date',
					type: 'date',
					filterable: true,
				},
				{
					label: 'Amount',
					field: 'amount_paid',
					type: 'number',
					filterable: true,
				},
				{
					label: 'Method',
					field: 'method',
					filterable: true,
				},
			],
			rows: [],
			actions: [{
				name: "Delete",
				icon: "ion-trash-a",
				callback: 'del',
			}],
			payment: {
				owner_id: 0,
				cost: 0,
				date: null,
				event_id: 0,
				ref: "",
			},
			creating: false,
            loaded:false,
            columnFiltersDefault: {},
            suplementing: false,
            supplementTotal: 0,
		}
	},
	created() {

        // Create our column filter default text here
        if(this.$route.params.user){
            // if we have a user, then they need to be what we are looking for
            // columnFiltersDefault.push
            let id = this.$route.params.user;

            axios.get('/api/users/' + id).then((response) => {
                this.columnFiltersDefault['first_name'] = response.data.first_name;
                this.columnFiltersDefault['last_name'] = response.data.last_name;
                this.columnFiltersDefault['id'] = response.data.id;
                // console.log(response.data.first_name);
            });
        }


		this.$Progress.start();
		axios.get('/api/payments/').then((response) => {

			for (let i = 0; i < response.data.length; i++) {
				let payment = response.data[i];

				let method = "online";
                // // console.log(payment.online);
				if (payment.online == 0) {
					method = "offline";
				}

				// construct the object for the row
                this.rows.push({
					payment_id: payment.id,
                    id: payment.owner_id,
                    first_name: payment.first_name,
                    last_name: payment.last_name,
                    group: payment.group,
                    parade: payment.parade,
                    date: payment.date,
                    amount_paid: payment.amount_paid,
                    method: method,
                });
			}
            this.loaded = true;
            this.$toasted.success("Loaded payments.").goAway(6000);

			this.$Progress.finish();

		});
	},
	methods: {
		save(data, index) {
			this.$Progress.start();
			// // console.log("Attempting to save");
			let payment = {
				user_id: this.payment.owner_id,
				ref: this.payment.ref,
				event_id: this.payment.event_id,
				date: this.payment.date,
				amount_paid: this.payment.cost,
				fee: 0,
				online: 0,
				status: 1,
			};

			axios.post('/api/payments/register', payment).then((response) => {
				// // console.log(response);
				// the response is the payment we want to add to the rowws
				let payment = response.data;


				this.$toasted.success("Success! Payment of $" + this.payment.cost + " added to database.").goAway(6000);

				// finally push to the rows
				this.$Progress.finish();

                this.payment = {
    				owner_id: 0,
    				cost: 0,
    				date: null,
    				event_id: 0,
    				ref: "",
    			};
                this.creating = false;
			}).catch((error) => {
				this.$Progress.fail();
				if (error.response) {
					// Unprocessable entity is 422
					if (error.response.status == 422) {
						// validation failed
						var obj = error.response.data;
						for (var key in obj) {
							// TODO PERHAPS MOVE THIS TO THE LARAVEL SIDE?
							let displayKey = key.charAt(0).toUpperCase() + key.slice(1);
							// replace underscores
							displayKey = displayKey.replace(/_/g, " ");

							this.$toasted.error(displayKey + " : " + obj[key]);
						}
					}
				} else {
					// console.log('Error', error.message);
				}
			})
		},
        addSupplementToUser(){
            this.$Progress.start();
			// // console.log("Attempting to save");
			let supplement = {
				user_id: this.payment.owner_id,
                total: this.supplementTotal
			};

			axios.post('/api/supplement/register', supplement).then((response) => {
				// // console.log(response);
				// the response is the payment we want to add to the rowws
				let supplement = response.data;


				this.$toasted.success("Success! Increased total to pay by: " + supplement.total).goAway(6000);

				// finally push to the rows
				this.$Progress.finish();
                this.supplementTotal = 0;
                this.supplementing = false;
			}).catch((error) => {
				this.$Progress.fail();
				if (error.response) {
					// Unprocessable entity is 422
					if (error.response.status == 422) {
						// validation failed
						var obj = error.response.data;
						for (var key in obj) {
							// TODO PERHAPS MOVE THIS TO THE LARAVEL SIDE?
							let displayKey = key.charAt(0).toUpperCase() + key.slice(1);
							// replace underscores
							displayKey = displayKey.replace(/_/g, " ");

							this.$toasted.error(displayKey + " : " + obj[key]);
						}
					}
				} else {
					// console.log('Error', error.message);
				}
			})
        },
		del(data, index) {
            this.$Progress.start();
            axios.delete('/api/payments/' + data.row.payment_id).then((response) => {
                // console.log(response);
                this.rows.splice(index,1);
            	this.$toasted.error("Success! Payment deleted.").goAway(6000);
            	this.$Progress.finish();
            });
		},
		create() {
			this.creating = !this.creating;
		},
        suplement() {
			this.suplementing = !this.suplementing;
		},
		onEventSelect(selected) {
			this.payment.event_id = selected.id;
		},
		onOwnerSelect(selected) {
			this.payment.owner_id = selected.id;
		},
	},
	components: {
		NSearch,
		VueNumeric,
		VueGoodTable
	}
}
</script>
