<template>
<div>
	<section class="section section-schedules-main" v-if="setSchedules.length == 0">
		<div class="container">
			<div class="row">
				<div class="col-xs-12 col-md-12">
					<div class="schedule-form-wrapper">
						<div class="schedule-form">
							<fieldset class="field-textinput">
								<label for="schedule-date">
                                    <span>Date</span>
                                    <hr class="bg-white">
                                </label>
								<input type="date" name="schedule-date" v-model="schedulePart.date" required>
							</fieldset>

							<fieldset class="field-textinput">
								<label for="name-group" class="currency">
                                    <span>Amount <vue-numeric currency="$" separator="," v-model="schedulePart.totalDue" read-only></vue-numeric></span>
                                    <hr class="bg-white">
                                </label>
								<input type="text" name="schedule-amount" v-model="schedulePart.totalDue">
								<!-- <vue-numeric currency="$" separator="," v-model="schedulePart.totalDue"></vue-numeric> -->

							</fieldset>

							<fieldset class="field-textinput field-button">
								<button type="submit" @click="add">Add New</button>
							</fieldset>
						</div>
					</div>
				</div>
			</div>


			<div class="row previous-schedules-row">
				<div class="col-xs-12 col-md-12">


					<div class="row schedule-row" v-for="schedule in schedules" :key="schedule">

						<div class="col-xs-12 col-md-4">
							<div class="prev-schedule-date prev-schedule-integer">
								<p>{{ schedule.date }}</p>
							</div>
						</div>
						<div class="col-xs-11 col-md-2">
							<div class="prev-schedule-amount prev-schedule-integer">
								<vue-numeric currency="$" separator="," v-model="schedule.amount_due" read-only></vue-numeric>

							</div>
						</div>
						<div class="col-xs-12 col-md-6">
							<button @click.prevent="deletePart(schedule)" class="no-border scheme-red"><i class="icon ion-trash-a"></i></button>
						</div>
					</div>

				</div>
			</div>
			<div class="row">
				<div class="col-xs-12 col-md-12">
                    <hr>
					<div class="schedule-summary text-uppercase">
						<p>
							TOTAL REQUESTED: <span class="schedule-summary-integer schedule-total-requested">
                                <vue-numeric currency="$" separator="," v-model="this.totalRequested" read-only></vue-numeric></span>
						</p>
						<p>
							TOTAL REQUIRED: <span class="schedule-summary-integer schedule-total-required">
							    <vue-numeric currency="$" separator="," v-model="this.totalRequired" read-only></vue-numeric>
							</span>
						</p>
						<p class="exceeded-value">{{ message }}</p>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-xs-12 col-md-12">
					<button @click="save">Save group</button>
				</div>
			</div>
		</div>
	</section>

    <section class="section section-schedules-main" v-else>
        <div class="container">

            <div class="row">
                <div class="col-xs-12 col-md-11">
                    <div class="row">

                        <div class="col-xs-12 col-md-6">
                            <div class="prev-schedule-date prev-schedule-integer">
                                <p>Date</p>
                            </div>
                        </div>
                        <div class="col-xs-11 col-md-6">
                            <div class="prev-schedule-amount prev-schedule-integer">
                                <p>Amount Due</p>
                            </div>
                        </div>
                    </div>

                    <div class="row" v-for="schedule in setSchedules" :key="schedule.totalDue">

                        <div class="col-xs-12 col-sm-6">
                            <div class="prev-schedule-date prev-schedule-integer">
                                <p>{{ schedule.date }}</p>
                            </div>
                        </div>
                        <div class="col-xs-11 col-sm-6">
                            <div class="prev-schedule-amount prev-schedule-integer">
                                <vue-numeric currency="$" separator="," v-model="schedule.amount_due" read-only></vue-numeric>
                            </div>
                        </div>

                    </div>

                    <div class="row">
                        <div class="col-xs-12">
                            <hr>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-xs-12 col-sm-6">
                            Total:
                        </div>

                        <div class="col-xs-12 col-sm-6">
                            <vue-numeric currency="$" separator="," v-model="totalRequired" read-only></vue-numeric>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-xs-12">
                            <hr>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>

</div>
</template>

<script>
import SchedulePart from './SchedulePart.vue';
import VueNumeric from 'vue-numeric';

export default {
	props: ['group', 'totalRequired'],
	data() {
		return {
			creating: true,
			groupID: this.group,
			schedulePart: {
				date: null,
				totalDue: "0",
			},
			schedules: [],
			setSchedules: [],
		}
	},
	mounted() {
		// console.log('Component mounted.')
	},
	created() {
		this.fetch();
	},
	methods: {
		fetch() {
			axios.get('/api/schedules/' + this.groupID, {}).then((response) => {
				// check if 401
				this.setSchedules = response.data;
			})
		},
		create() {
			// console.log("trying to createe");
			this.creating = true;
		},
		add() {
			if (this.schedulePart.date != null &&
				this.schedulePart.totalDue > 0) {
                    // Adds to the current list of schedules
                    // console.log("adding");
                    let merged = false;
                    for(let i = 0; i < this.schedules.length; i++){
                        // check the dates
                        // if they match
                        // merge
                        let current = this.schedules[i];

                        if(current.date === this.schedulePart.date){
                            this.schedules[i].amount_due = parseInt(this.schedules[i].amount_due) + parseInt(this.schedulePart.totalDue);
                            this.$toasted.success("The schedule dates are the same and have been merged.");
                            merged = true;
                        }

                    }
                    if(!merged){
                        this.schedules.push({
                            date: this.schedulePart.date,
                            amount_due: this.schedulePart.totalDue,
                        });
                    }
                    this.schedules = this.sortedScheduleByDate;
                    this.schedulePart = {
                        date: null,
                        amount_due: 0,
                    };
			}else{
                if (this.schedulePart.date == null) {
                    this.$toasted.error("You must enter a date.");
                }else if(this.schedulePart.totalDue == 0){
                    this.$toasted.error("Amount due must be above zero.");
                }
                // must finish typing inputs before its done

            }
		},
		deletePart(schedule) {
			var index = this.schedules.indexOf(schedule);
			if (index > -1) {
				this.schedules.splice(index, 1);
			}
			// console.log("deleteing part");
		},
		save() {
            this.$Progress.start();
			// Saves all schedules to the database
			axios.post('/api/schedules/register', {
					schedules: this.schedules,
					group: this.groupID,
				})
				.then((response) => {
					// console.log("----------------");

					// console.log(response);
                    this.$Progress.finish();
					this.$toasted.success("Success! Schedule saved.").goAway(6000);
					// reset the participant
					this.setSchedules = response.data;
					this.creating = false;
				})
				.catch((error) => {
                    this.$Progress.fail();
					if (error.response) {
						// The request was made and the server responded with a status code
						// that falls out of the range of 2xx
						if (error.response.status == 422) {
							// validation failed
							// this.$toasted.show(error.response.status);
							var obj = error.response.data;

							for (var key in obj) {
								// console.log(key); // key1 and etc...
								// console.log(obj[key]); // val1 and etc...
								this.$toasted.error(key + " : " + obj[key]);
							}

						} else if (error.response.status == 405) {

						}

						// console.log(error.response.data);
						// console.log(error.response.status);
						// console.log(error.response.headers);
					} else if (error.request) {
						// The request was made but no response was received
						// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
						// http.ClientRequest in node.js
						// console.log(error.request);
					} else {
						// Som{{ group.fee }}ething happened in setting up the request that triggered an Error
						// console.log('Error', error.message);
					}
				})
		},
		delSchedule(schedule) {
			axios.delete('/api/schedules/' + schedule.id + '/delete')
				.then((response) => {
					var index = this.groups.indexOf(schedule);
					if (index > -1) {
						this.schedules.splice(index, 1);
					}
				})
				.catch((error) => {
					// console.log(error);
					// TODO remove this. it always fails at the moment
					var index = this.schedules.indexOf(schedule);
					if (index > -1) {
						this.schedules.splice(index, 1);
					}
				});
		},
	},
	components: {
		SchedulePart,
		VueNumeric
	},
	computed: {
        message(){

            let datesCorrect = false;

            if(this.totalRequested > this.totalRequired){
                // check if they are asking for too much
                return "Total Requested exceeds total required by: $" + this.totalNeeded;
            }else if(this.totalRequested < this.totalRequired){
                // check if they are asking for too little
                // check if they are asking for too much
                return "Total Requested falls short of the total required by: $" + this.totalNeeded;
            }else{
                // and one final message when they get it correct
                // check if they are asking for too much
                return "Schedule total equals total required.";
            }

        },
		sortedScheduleByDate() {
			this.schedules.sort((a, b) => {
				return new Date(a.date) - new Date(b.date);
			});
			return this.schedules;
		},
		totalRequested() {
			let requested = 0;
			for (let schedule of this.schedules) {
				requested += parseInt(schedule.amount_due);
			}
			return requested;
		},
		totalNeeded() {
			return this.totalRequired - this.totalRequested;
		},
	}
}
</script>
