<template>
<div class="table">
	<form class="search-form search-table-from">
		<input type="text" v-model="search" v-bind:placeholder="placeholder">
	</form>
    <form>
    	<ul class="participants-list participants-from">

    		<div v-for="(result,index) in results" v-if="results != null && results.length > 0">
    			<div :class='{search: radioStyle}' class="search">
                    <label class="radio-label" :for="id + '_' + index">
                        <input  type="radio" name="radio" :id="id + '_' + index" v-model="currentlySelected" v-bind:value="result">
                        <span></span>
                        <p class="display">
                            <span class="display" v-for="display in displays"> - {{ result[display] }}</span>
                        </p>
                    </label>
    			</div>
    		</div>
    		<div v-if="results != null && results.length == 0">
    			<p>No results found.</p>
    		</div>
    	</ul>
    </form>
</div>
</template>

<script>
export default {
	props: ['placeholder', 'type', 'callback', 'model', 'displays', 'exclude', 'radioStyle', 'firstPerson', 'secondPerson'],
	data() {
		return {
			id: this._uid + this.guid(),
			search: "",
			results: null,
			currentlySelected: null,
		}
	},
	created() {
		// console.log(this._uid);
		axios.get('/api/search/' + this.type + "?q=" + this.search).then((response) => {
			// Maybe some error checking
			if (this.exclude) {
				var i = this.indexOf(response.data, this.exclude.id);
				response.data.splice(i, 1);
			}
			this.results = response.data;
		})
	},
	methods: {
		indexOf(array, id) {
			for (const [index, value] of array.entries()) {
				if (value.id === id) {
					return index;
				}
			}
		},
		guid() {
			function s4() {
				return Math.floor((1 + Math.random()) * 0x10000)
					.toString(16)
					.substring(1);
			}
			return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
		}
	},
	watch: {
		search(search) {
			// it would be beneficial to cache some of this perhaps?
			// could save the results to a global
			// global dependant on the type of data we are searching, then load the same list back in
			// avoid multiple searchs?
			axios.get('/api/search/' + this.type + "?q=" + this.search).then((response) => {
				// Maybe some error checking
				// console.log(response);
				if (this.exclude != null) {
					var i = this.indexOf(response.data, this.exclude.id);
					response.data.splice(i, 1);
				}
				this.results = response.data;
			})
		},
		exclude(exclude) {
			axios.get('/api/search/' + this.type + "?q=" + this.search).then((response) => {
				// Maybe some error checking
				// console.log(response);
				if (this.exclude != null) {
					var i = this.indexOf(response.data, this.exclude.id);
					response.data.splice(i, 1);
				}
				this.results = response.data;
			})
		},
		currentlySelected(currentlySelected) {
			if (this.secondPerson != undefined) {
				// console.log("secondPerson");
				this.$emit('secondPerson', currentlySelected);
			} else if (this.firstPerson != undefined) {
				// console.log("firstPerson");
				this.$emit('firstPerson', currentlySelected);
			} else {
                this.$emit('callback', currentlySelected);
			}

		}
	},
}
</script>
