<template>
<div>
	<div class="viewing" v-if="viewing">
		{{ this.page.title }}
        <router-link :to="{ path:'/pages/' + this.page.id }">
            <button>Edit</button>
        </router-link>
	</div>
    <div class="editing" v-else>
        <input type="text" v-model="title" placeholder="title">
        <textarea v-model="description" placeholder="description"></textarea>
        <input type="text" v-model="slug" placeholder="slug">
        <textarea v-model="content" placeholder="content"></textarea>
        <div class="preview" v-html="content"></div>
    	<button @click="save">SAVE</button>
    </div>
</div>
</template>

<script>
export default {
	props: ['page','editCallback'],
	data() {
		return {
            id: this.page.id,
            title: this.page.title,
            description: this.page.description,
            slug: this.page.slug,
            content: this.page.content,
			viewing: true,
		}
	},
	created() {},
	methods: {
		edit: function() {
			this.viewing = false;
		},
		save: function() {
			var that = this;
			// console.log("trying to save");

			// try saving
			axios.put('/api/pages/' + that.id, {
                id: that.id,
                title: that.title,
                description: that.description,
                slug: that.slug,
                content: that.content,
			})
			.then(function(response) {
				that.viewing = true;
                // emit to list of pages the update
                that.$emit('editCallback',{
                    id: that.id,
                    title: that.title,
                    description: that.description,
                    slug: that.slug,
                    content: that.content,
                })

			})
			.catch(function(error) {
				// console.log(error);
				that.viewing = true;
			});
		}
	}
}
</script>
