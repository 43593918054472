<template>
<div>
    <section class="section section-welcome-message">
        <div class="container">
            <div class="row">
                <div class="col-xs-12">
                    <h1 class="welcome-message">Archived Users</h1>
                </div>
            </div>
        </div>
    </section>

	<section class="section">
		<div class="container">
			<div class="row">

				<div class="col-sm-12 col-block-5">
					<router-link to="/">
						<button class="dashboard-button active">
                                    <i class="icon ion-speedometer"></i>
                                    <p>Dashboard</p>
                                </button>
					</router-link>
				</div>

				<div class="col-sm-6 col-block-5">
					<router-link to="/events">
						<button class="dashboard-button">
                                    <i class="icon ion-calendar"></i>
                                    <p>Parades</p>
                                </button>
					</router-link>
				</div>

				<div class="col-sm-6 col-block-5">
					<router-link to="/groups">
						<button class="dashboard-button">
                                    <i class="icon ion-ios-people"></i>
                                    <p>Groups</p>
                                </button>
					</router-link>
				</div>

				<div class="col-sm-6 col-block-5">
					<router-link to="/payments">
						<button class="dashboard-button">
                                    <i class="icon ion-social-usd-outline"></i>
                                    <p>Payments</p>
                                </button>
					</router-link>
				</div>

				<div class="col-sm-6 col-block-5">
					<router-link to="/pages">
						<button class="dashboard-button">
                                    <i class="icon ion-document"></i>
                                    <p>Pages</p>
                                </button>
					</router-link>
				</div>
			</div>
		</div>
	</section>

	<section class="section section-participant-list">

		<div class="container">
			<div class="row">
				<div class="col-xs-12 col-md-12">
					<div class="row">
						<div class="col-xs-12">
							<router-link to="/">
								<button>Reurn to home</button>
							</router-link>
						</div>
					</div>

				</div>
			</div>

			<div v-if="dataToLoad">
				<vue-good-table v-if="loaded" title="Participants" :columns="columns" :rows="rows" :paginate="true" :actions="actions"  @restore="restore" @view="view" />
				<div v-else style="margin:30px auto; text-align:center;">
					<div class="mnml-spinner dark" style="margin:0 auto">
					</div>
					<p class="text-small" style="margin-top:20px;">Loading Participants...</p>

				</div>
			</div>
			<div v-else>
				<br><br><br>
				<p class="text-small text-center">No deleted users.</p>
			</div>

		</div>

	</section>
</div>
</template>

<script>
import Participant from './Participant.vue';
import TransferFunds from '../TransferFunds.vue';
import Search from '../Search.vue';
import NSearch from '../NSearch.vue';
import VueGoodTable from '../Table.vue';

export default {
	props: ['fromGroup'],
	data() {
		return {
			columns: [{
					label: 'First Name',
					field: 'first_name',
					filterable: true,
					editable: true,
				},
				{
					label: 'Last Name',
					field: 'last_name',
					filterable: true,
					editable: true,
				},
				{
					label: 'E-mail',
					field: 'email',
					filterable: true,
					editable: true,
				},
				{
					label: 'Total Paid',
					field: 'total_paid',
					type: 'number',
					html: false,
					filterable: true,
				},
			],
			rows: [],
			actions: [{
				name: "Payments",
				icon: "ion-eye",
				callback: 'view',
			}, {
				name: "Restore user",
				icon: "ion-ios-reload",
				callback: 'restore',
			}],
			creating: false,
			participant: {
				first_name: null,
				last_name: null,
				email: null,
				password: null,
				group: null,
			},
			participants: [],
			filteredGroupItems: [],
			loaded: false,
			dataToLoad: false,
		}
	},
	created() {
		this.fetchAll();
	},
	methods: {
		view(data, index) {
			this.$router.push({
				path: '/payments/' + data.row.id,
				params: {
					id: data.row.id
				}
			})
		},
		restore(data, index) {
			this.$Progress.start();

			axios.get('/api/archived/users/restore/' + data.row.id).then((response) => {
				// console.log(response);
                this.rows.splice(data.index, 1);
                if(!this.rows.length){
                    this.dataToLoad = false;
                }
				this.$Progress.finish();
				this.$toasted.success("Success! User: '" + data.row.first_name + "' restored!").goAway(6000);
			});
		},
		fetchAll() {
			this.$Progress.start();

			axios.get('/api/archived/users/count').then((response) => {
				if (response.data.count >= 1) {
					this.dataToLoad = true;
				}
			});
			axios.get('/api/archived/users').then((response) => {
				this.participants = response.data;

				for (let i = 0; i < this.participants.length; i++) {
					let p = this.participants[i];
					let totalRequired = 0;
					if (p.group != undefined) totalRequired = p.group.fee;

					let colour = "normal";

					// TODO WHAT DECIDES THE HIGHLIGHTING
					// decide whether they should highlight in red or yellow or nothing at all
					if ((p.total_due_today - p.total_paid) == 100) {
						colour = 'yellow';
					} else if ((p.total_due_today - p.total_paid) >= 200) {
						colour = 'red';
					}

					this.rows.push({
						id: p.id,
						first_name: p.first_name,
						last_name: p.last_name,
						email: p.email,
						package_cost: parseInt(p.total_required),
						total_paid: parseInt(p.total_paid),
						total_due_today: p.total_due_today,
						total_overdue: (p.total_due_today - p.total_paid),
						total_remaining: (p.total_required - p.total_paid),
						colour: colour,
					});

				}

			})

		},
		create() {
			this.creating = true;
		},
		updateSearch(items) {
			this.filteredGroupItems = items;
		},
		update(participant) {

			let updated = false;
			for (var i = 0; i < this.participants.length; i++) {
				if (this.participants[i].id == participant.id) {
					// console.log(this.participants[i]);
					this.participants[i].first_name = participant.first_name;
					this.participants[i].last_name = participant.last_name;
					this.participants[i].email = participant.email;
				}
			}

		},
		onGroupSelect(selected) {
			this.participant.group = selected.id;
		},

	},
	watch: {
		rows() {
			if (this.rows.length == this.participants.length) {
				this.loaded = true;
				this.$toasted.success("Loaded participants.").goAway(6000);
			}
		}
	},
	components: {
		Participant,
		TransferFunds,
		Search,
		NSearch,
		VueGoodTable,
		// Datatable,
	},
}
</script>
