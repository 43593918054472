<template>
<div>
	<section class="section section-welcome-message section-schedule-welcome-message">
		<div class="container">
			<div class="row">
				<div class="col-xs-12 col-sm-9">
					<h1 class="welcome-message">
                        <span v-if="setSchedules == null || setSchedules.length == 0">
                            Creating a payment schedule
                        </span>
                        <span v-else>
                            Viewing payment schedule
                        </span>
                    </h1>
					<p class="schedule-for">Schedule for: "<span class="schedule-group">{{ group.name }}</span>"</p>
				</div>

				<div class="col-xs-12 col-sm-3">
					<router-link :to="groupName == 'groupadmin-schedule' ? '/' : '/groups'">
						<button>Back</button>
					</router-link>
				</div>

			</div>
		</div>
	</section>

	<Schedules :group="group.id" :totalRequired="group.fee"></Schedules>
</div>
</template>

<script>
import Schedules from '../PaymentSchedule/Schedules.vue';
import VueNumeric from 'vue-numeric';

export default {
	created() {
		let id = this.$route.params.id;
		// load data about this particular group
		axios.get('/api/groups/' + id).then((response) => {
			this.groupName = this.$route.name;
			this.group = response.data;
			this.group.name = response.data.name;
			this.group.description = response.data.description;
			this.group.fee = response.data.fee;
			this.group.owner_id = response.data.owner_id;
			this.group.event_id = response.data.event_id;
		})

        let total = 0;
		axios.get('/api/groups/' + id + '/schedules').then((response) => {
            for (let schedule of response.data) {
                total += schedule.amount_due;
            }
            this.totalRequested = total;
            this.setSchedules = response.data;
		})
	},
	mounted() {
		// // console.log('Component mounted.')
	},
	data() {
		return {
			groupName: this.$route.name,
			group: {
				id: this.$route.params.id,
				name: null,
				description: null,
				fee: null,
				owner_id: null,
				event_id: null,
			},
			name: "",
			description: "",
			fee: null,
			owner_id: null,
			event_id: null,
            setSchedules:null,
            totalRequested: null,
		}
	},
	components: {
		Schedules,
		VueNumeric
	}
}
</script>
