<template>
<div>
	<section class="section section-welcome-message">
		<div class="container">
			<div class="row">
				<div class="col-xs-12">
					<h1 class="welcome-message">Managing Parades</h1>
				</div>
			</div>
		</div>
	</section>

	<section class="section">
		<div class="container">
			<div class="row">

				<div class="col-sm-12 col-block-5">
					<router-link to="/">
						<button class="dashboard-button">
                            <i class="icon ion-speedometer"></i>
                            <p>Dashboard</p>
                        </button>
					</router-link>
				</div>

				<div class="col-sm-6 col-block-5">
					<router-link to="/events">
						<button class="dashboard-button active">
                            <i class="icon ion-calendar"></i>
                            <p>Parades</p>
                        </button>
					</router-link>
				</div>

				<div class="col-sm-6 col-block-5">
					<router-link to="/groups">
						<button class="dashboard-button">
                            <i class="icon ion-ios-people"></i>
                            <p>Groups</p>
                        </button>
					</router-link>
				</div>

				<div class="col-sm-6 col-block-5">
					<router-link to="/payments">
						<button class="dashboard-button">
                            <i class="icon ion-social-usd-outline"></i>
                            <p>Payments</p>
                        </button>
					</router-link>
				</div>

				<div class="col-sm-6 col-block-5">
					<router-link to="/pages">
						<button class="dashboard-button">
                            <i class="icon ion-document"></i>
                            <p>Pages</p>
                        </button>
					</router-link>
				</div>
			</div>
		</div>
	</section>


	<section class="section section-event-summary">

		<div class="container">
			<div class="row">


				<div class="col-xs-12 col-md-12">
					<router-link to="/events/new">
						<button>Create new parade</button>
					</router-link>

					<div class="row group-summary-row" v-for="parade in events">
						<div class="col-xs-12 col-sm-8 col-md-9">
							<div class="group-summary-inner-wrapper">
								<div class="group-summary-details">
									<p class="group-name">
										{{ parade.title }}
									</p>
									<p class="group-payment-progress">
										<span class="group-summary-percent-paid">
                                        <strong>{{ parade.date }}</strong> | Tax Date: {{ parade.tax_date }}
                                        </span>
									</p>
								</div>
								<div class="group-summary-description">
									{{ parade.description }}

									<br><br>
									<p class="text-small">GROUPS ON THIS PARADE: </p>

									<div class="col-xs-7" v-for="group in parade.groups">
										<router-link :to="{ path:'/groups/' + group.id + '/view'}">
											<button class="no-border">
                                                {{ group.name }}
                                            </button>
										</router-link>
									</div>

								</div>
							</div>
						</div>

						<div class="col-xs-12 col-sm-4 col-md-3">
							<div class="group-summary-options">
								<router-link :to="{ path:'/groups/new' }">
									<button class="no-border scheme-green">
                                        Add Group
                                    </button>
								</router-link>

								<router-link :to="{ path:'/events/' + parade.id }">
									<button class="no-border">
                                        <i class="icon ion-edit" /> Edit
                                    </button>
								</router-link>
								<br>

								<button v-if="parade.groups.length == 0" class="scheme-red no-border" @click.prevent="deleteEvent(parade)"><i class="icon ion-trash-a" /> Delete</button>
							</div>
						</div>

						<div class="col-xs-12">
							<hr>
						</div>
					</div>
                    <div class="row">

                        <div class="col-xs-12">

                            <br><br><br><br>
                            <p class="text-small">Archived Events:</p>
                            <hr>
                            <br><br><br><br>
                        </div>


                    </div>


					<div class="row group-summary-row" v-for="parade in archivedEvents">
						<div class="col-xs-12 col-sm-8 col-md-9">
							<div class="group-summary-inner-wrapper">
								<div class="group-summary-details">
									<p class="group-name">
										{{ parade.title }}
									</p>
									<p class="group-payment-progress">
										<span class="group-summary-percent-paid">
                                        <strong>{{ parade.date }}</strong> | Tax Date: {{ parade.tax_date }}
                                        </span>
									</p>
								</div>
								<div class="group-summary-description">
									{{ parade.description }}

									<br><br>
									<p class="text-small">GROUPS ON THIS PARADE: </p>

									<div class="col-xs-7" v-for="group in parade.groups">
										<router-link :to="{ path:'/groups/' + group.id + '/view'}">
											<button class="no-border">
                                                {{ group.name }}
                                            </button>
										</router-link>
									</div>

								</div>
							</div>
						</div>

						<div class="col-xs-12 col-sm-4 col-md-3">
							<div class="group-summary-options">
								<router-link :to="{ path:'/groups/new' }">
									<button class="no-border">
                                        Add Group
                                    </button>
								</router-link>

								<router-link :to="{ path:'/events/' + parade.id }">
									<button class="no-border">
                                        <i class="icon ion-edit" /> Edit
                                    </button>
								</router-link>
								<br>
								<button class="scheme-red no-border" @click.prevent="deleteEvent(parade)"><i class="icon ion-trash-a" /> Delete</button>
							</div>
						</div>

						<div class="col-xs-12">
							<hr>
						</div>
					</div>

				</div>
			</div>
		</div>
	</section>
</div>
</template>

<script>
import Event from './Event.vue';

export default {
	data() {
		return {
			parade: {
				creating: false,
				title: null,
				description: null,
				date: null,
				tax_date: null,
			},
			creating: false,
			events: [],
			archivedEvents: [],
		}
	},
	created() {
		this.fetch();
	},
	methods: {
		fetch() {
			axios.get('/api/events/withgroups').then((response) => {
				// check if 401
				this.events = response.data;
			})

			axios.get('/api/archived/events').then((response) => {
				// check if 401
				this.archivedEvents = response.data;
			})

		},
		create() {
			this.creating = true;
		},
		update(parade) {
			// console.log(parade);
			for (var i = 0; i < this.events.length; i++) {
				if (parade.id == this.events[i].id) {
					this.events[i].title = parade.title;
					this.events[i].description = parade.description;
					this.events[i].date = parade.date;
					this.events[i].tax_date = parade.tax_date;
				}
			}
		},
		save() {
			// save the new participant
			axios.post('/api/events/register', {
					title: this.parade.title,
					description: this.parade.description,
					date: this.parade.date,
					tax_date: this.parade.tax_date,
				})
				.then((response) => {
					this.events.push(this.parade);

					this.$toasted.success("Success! Event '" + this.parade.title + "' saved.").goAway(6000);

					// reset the participant
					this.parade = {
						title: null,
						description: null,
						date: null,
						tax_date: null,
					};
					this.creating = false;
					// this.events = response.data;
				})
				.catch((error) => {
					if (error.response) {
						// The request was made and the server responded with a status code
						// that falls out of the range of 2xx
						if (error.response.status == 422) {

							// validation failed
							var obj = error.response.data;
							// console.log(obj);
							for (var key in obj) {
								let displayKey = key.charAt(0).toUpperCase() + key.slice(1);
								displayKey = displayKey.replace(/_/g, " ");
								this.$toasted.error(displayKey + " : " + obj[key]);
							}

						} else if (error.response.status == 405) {

						}
					} else if (error.request) {
						// The request was made but no response was received
						// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
						// http.ClientRequest in node.js
					} else {
						// Something happened in setting up the request that triggered an Error
					}
				})
		},
		deleteEvent(parade) {
			this.$Progress.start();
			
			axios.delete('/api/events/' + parade.id)
				.then((response) => {

                    // find it out of the normal list
					var index = -1;
					for (var i = 0; i < this.events.length; i++) {
						if (this.events[i].id === parade.id) {
							index = i;
						}
					}
                    // check the archived events list
					for (var i = 0; i < this.archivedEvents.length; i++) {
						if (this.archivedEvents[i].id === parade.id) {
							index = i;
						}
					}

                    // if we find it here it is in the archived list
                    if (index > -1) {
						this.archivedEvents.splice(index, 1);
						this.$Progress.finish();
						this.$toasted.error("Success! Event: '" + parade.title + "' deleted.").goAway(6000);
					}
				})
				.catch((error) => {
					this.$Progress.fail();
					if (error.response) {
						// The request was made and the server responded with a status code
						// that falls out of the range of 2xx
						if (error.response.status == 422) {

							// validation failed
							var obj = error.response.data;
							// console.log(obj);
							for (var key in obj) {
								let displayKey = key.charAt(0).toUpperCase() + key.slice(1);
								displayKey = displayKey.replace(/_/g, " ");
								// console.log(key); // key1 and etc...
								// console.log(obj[key]); // val1 and etc...
								this.$toasted.error(displayKey + " : " + obj[key]);
							}
						}

					} else {
						// Something happened in setting up the request that triggered an Error
						// console.log('Error', error.message);
					}
				})
		},
		undo() {
			// console.log("TRIGGERED");
		},
	},
	components: {
		Event,
	}
}
</script>
