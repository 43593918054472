<template>
<div>
	<section class="section section-welcome-message">
		<div class="container">
			<div class="row">
				<div class="col-xs-12 col-sm-9">
					<h1 class="welcome-message">New Group</h1>
				</div>
				<div class="col-xs-12 col-sm-3">
					<br>
					<router-link to="/groups">
						<button>Back</button>
					</router-link>
				</div>
			</div>
		</div>
	</section>

	<section class="section section-new-group">
		<div class="container">
			<div class="row">

				<div class="col-xs-12 col-sm-12">
					<p>Create Group Admin</p>
					<fieldset class="field-textinput">
						<label for="name-group">
                                <span>First Name</span>
                                <hr class="bg-white">
                            </label>
						<input type="text" name="name-group" v-model="owner.first_name" placeholder="First Name">
					</fieldset>

					<fieldset class="field-textinput">
						<label for="name-group">
                                <span>Last Name</span>
                                <hr class="bg-white">
                            </label>
						<input type="text" v-model="owner.last_name" placeholder="Last Name"/>
					</fieldset>

					<fieldset class="field-textinput">
						<label for="name-group">
                                <span>E-mail</span>
                                <hr class="bg-white">
                            </label>
						<input  v-model="owner.email" placeholder="E-mail"/>
					</fieldset>
					<br><br>
				</div>

				<div class="col-xs-12">
					<p>Choose event:</p>
					<NSearch :type="'events'" :placeholder="'Search for events'" :model="'id'" :displays="['title']" @callback="onEventSelect"></NSearch>
					<hr>
				</div>


				<div class="col-xs-12 col-md-12">
					<div action="" class="form-NewGroup">

						<!-- Standard field input -->
						<fieldset class="field-textinput">
							<label for="name-group">
                                <span>Group Name</span>
                                <hr class="bg-white">
                            </label>
							<input type="text" name="name-group" v-model="name" placeholder="Name">
						</fieldset>

						<fieldset class="field-textinput">
							<label for="name-group" class="currency">
                                <span>Cost per participant <vue-numeric currency="$" separator="," v-model="fee" read-only></vue-numeric></span>
                                <hr class="bg-white">
                            </label>

							<input type="number" v-model="fee" placeholder="Cost per participant" step="any">
						</fieldset>



						<!-- Textarea -->
						<fieldset class="field-textarea">
							<label for="description-group">
                            <span>Group Description</span>
                            <hr class="bg-white">
                        </label>
							<textarea name="description-group" id="description-group" cols="30" rows="8" v-model="description" placeholder="Description"></textarea>
						</fieldset>

						<fieldset class="field-textinput">
							<label for="name-group" class="currency">
                                <span>Group Logo (320 x 200)</span>
                                <hr class="bg-white">
                            </label>
							<div v-if="!image">
								<label class="btn btn-default btn-file">
                                    Browse for image<input type="file" style="display: none;" @change="onImageChange">
                                </label>
							</div>
							<div v-else>
								<img :src="image" style="max-width:320px; max-height: 200px;" />
								<button @click="changeImage" class="no-border">Change image</button>
							</div>
						</fieldset>

						<fieldset class="field-textinput">
							<label for="name-group" class="currency">
                                <span>Groups Terms</span>
                                <hr class="bg-white">
                            </label>
							<div v-if="!file">
								<label class="btn btn-default btn-file">
                                    Upload Terms and Conditions<input type="file" style="display: none;" @change="onFileChange">
                                </label>
							</div>
							<div v-else>
                                <label class="btn btn-default btn-file" @click="changeImage">
                                    Change terms and conditions sheet
                                </label>
							</div>
						</fieldset>
                        <br><br>
	                    <button class="button-link" @click="save">SAVE</button>
					</div>
				</div>

			</div>
		</div>
	</section>
</div>
</template>

<script>
import VueNumeric from 'vue-numeric';

import NSearch from '../NSearch.vue';
import GroupOwner from './GroupOwner.vue';
export default {
	mounted() {
		// // console.log('Component mounted.')
	},
	data() {
		return {
			name: "",
			description: "",
			fee: 0,
			owner_id: null,
			event_id: null,
			image: null,
			fileImage: null,
			file: null,
			owner: {
				first_name: "",
				last_name: "",
				email: "",
				password: this.generateRandomPassword(),
				group: 0,
			}
		}
	},
	methods: {
		save() {
			this.$Progress.start();

			// create the user here
			axios.post('api/users/register/owner', {
					first_name: this.owner.first_name,
					last_name: this.owner.last_name,
					email: this.owner.email,
					password: this.owner.password,
					group: this.owner.group
				})
				.then((response) => {

					this.$toasted.success("Success! Group Owner: " + this.owner.first_name + " saved.").goAway(6000);
					this.owner_id = response.data.id;
					// GROUP SAVE
					// save the new participant
					const formData = new FormData();
					const config = {
						headers: {
							'content-type': 'multipart/form-data'
						}
					}
					var data = new FormData();
					data.append('name', this.name);
					data.append('description', this.description);
					data.append('fee', this.fee);
					data.append('owner_id', this.owner_id);
					data.append('event_id', this.event_id);
					if(this.file){
						data.append('file', this.file);
					}
					if(this.fileImage){
						data.append('image', this.fileImage);
					}

					axios.post('/api/groups/register', data, config)
						.then((response) => {
							// console.log("----------------");
							// console.log(response);

							this.$toasted.success("Success! Group: " + this.name + " saved.").goAway(6000);
							this.$Progress.finish()
							this.$router.push({
								path: '/groups/' + response.data.id + "/schedule"
							})

							// reset the participant
							// this.group = {
							//     name: null,
							//     description:null,
							//     owner_id:null,
							//     event_id:null,
							//     fee:null,
							// };
							// this.creating = false;

						})
						.catch((error) => {
							this.$Progress.fail()
							// console.log(error);
							if (error.response) {
								// The request was made and the server responded with a status code
								// that falls out of the range of 2xx
								if (error.response.status == 422) {
									// validation failed
									var obj = error.response.data;
									// console.log(obj);
									for (var key in obj) {
										// make first letter uppercase
										let displayKey = key.charAt(0).toUpperCase() + key.slice(1);
										// replace underscores
										displayKey = displayKey.replace(/_/g, " ");
										// console.log(key); // key1 and etc...
										// console.log(obj[key]); // val1 and etc...
										this.$toasted.error(displayKey + " : " + obj[key]);
									}
								} else if (error.response.status == 405) {

								}
								// console.log(error.response.data);
								// console.log(error.response.status);
								// console.log(error.response.headers);
							} else if (error.request) {
								// The request was made but no response was received
								// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
								// http.ClientRequest in node.js
								// console.log(error.request);
							} else {
								// Something happened in setting up the request that triggered an Error
								// console.log('Error', error.message);
							}
						})

				})
				.catch((error) => {
					this.$Progress.fail()
					// console.log(error);
					// console.log(data);
					if (error.response) {
						// The request was made and the server responded with a status code
						// that falls out of the range of 2xx
						if (error.response.status == 422) {
							var obj = error.response.data;
							// console.log(obj);
							for (var key in obj) {
								// make first letter uppercase
								let displayKey = key.charAt(0).toUpperCase() + key.slice(1);
								// replace underscores
								displayKey = displayKey.replace(/_/g, " ");
								// console.log(key); // key1 and etc...
								// console.log(obj[key]); // val1 and etc...
								this.$toasted.error(displayKey + " : " + obj[key]);
							}

						} else if (error.response.status == 405) {
							// validation failed
						}
						// console.log(error);
						// console.log(error.response.data);
						// console.log(error.response.status);
						// console.log(error.response.headers);
					} else if (error.request) {
						// The request was made but no response was received
						// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
						// http.ClientRequest in node.js
						// console.log(error.request);
					} else {
						// Something happened in setting up the request that triggered an Error
						// console.log('Error', error.message);
					}
				})



		},
		onEventSelect(selected) {
			this.event_id = selected.id;
		},
		onOwnerSelect(selected) {
			this.owner_id = selected;
		},
		generateRandomPassword() {
			return Math.random().toString(36).slice(-8);
		},
		onImageChange(e) {
			var files = e.target.files || e.dataTransfer.files;
			if (!files.length)
				return;

			this.createImage(files[0]);
		},
		onFileChange(e) {
			var files = e.target.files || e.dataTransfer.files;
			if (!files.length)
				return;
			this.file = files[0];

			// I guess we could add something saying its changed

		},
		createImage(file) {
			var image = new Image();
			var reader = new FileReader();
			var vm = this;

			reader.onload = (e) => {
				vm.image = e.target.result;
			};
			reader.readAsDataURL(file);

			this.fileImage = file;

		},
		changeImage: function(e) {
			this.image = '';
		}
	},
	components: {
		NSearch,
		GroupOwner,
		VueNumeric
	}
}
</script>
