<template>
<div>
	<div v-if="userID != 0">
		<fieldset class="field-textinput field-inputandbutton">
			<label for="name-group">
                <span>Group Admin</span>
                <hr class="bg-white">
            </label>
			<input type="text" name="name-group" :value="first_name" readonly>
		</fieldset>

		<fieldset class="field-textinput">
			<label for="name-group">
                <span></span>
            </label>
			<button @click="changeOwner" class="button-link">Edit</button>
		</fieldset>
	</div>

	<div v-else>
		<br>
		<div class="row">

			<div class="col-xs-12 col-sm-12">
				<p>Create Admin</p>
				<fieldset class="field-textinput">
					<label for="name-group">
                            <span>First Name</span>
                            <hr class="bg-white">
                        </label>
					<input type="text" name="name-group" v-model="first_name" placeholder="First Name">
				</fieldset>

				<fieldset class="field-textinput">
					<label for="name-group">
                            <span>Last Name</span>
                            <hr class="bg-white">
                        </label>
					<input type="text" v-model="last_name" placeholder="Last Name"/>
				</fieldset>

				<fieldset class="field-textinput">
					<label for="name-group">
                            <span>E-mail</span>
                            <hr class="bg-white">
                        </label>
					<input  v-model="email" placeholder="E-mail"/>
				</fieldset>
				<div>
					<fieldset class="field-textinput">
						<label for="name-group">
                                <span></span>
                            </label>
						<button @click="save">Save new user</button>
					</fieldset>
				</div>
			</div>
			<div class="col-xs-12 col-sm-8">
                <p>Or</p>

        		<NSearch :type="'users'" :placeholder="'Search for owner'" :model="'id'" :displays="['first_name']" @callback="ownerSelect"></NSearch>
            </div>

		</div>



		<br>


	</div>

</div>
</template>

<script>
import NSearch from '../NSearch.vue';

export default {
	props: ['groupID', 'callback', 'groupOwner'],
	// TODO a bit hackey
	data() {
		return {
			first_name: "",
			last_name: "",
			email: "",
			password: this.generateRandomPassword(),
			group: 0,
			userID: 0,
		}
	},
	mounted() {
		if (this.groupOwner) {
			this.first_name = this.groupOwner.first_name;
			this.last_name = this.groupOwner.last_name;
			this.email = this.groupOwner.email;
			this.userID = this.groupOwner.id;
		}

		if (this.groupID) {
			this.group = this.groupID;
		}
	},
	methods: {
		save() {
			this.$Progress.start()

            // pass the user back up to the group creation and then it can create it at the same tim



			axios.post('/api/users/register/owner', {
				first_name: this.first_name,
				last_name: this.last_name,
				email: this.email,
				password: this.password,
				group: this.group
			})
			.then((response) => {
				// console.log("----------------");
				// console.log(response.data);
				this.ownerSelect(response.data);
				this.$toasted.success("Success! Group Owner: " + this.first_name + " saved.").goAway(6000);
				this.$Progress.finish()
			})
			.catch((error) => {
				this.$Progress.fail()
				if (error.response) {
					// The request was made and the server responded with a status code
					// that falls out of the range of 2xx
					if (error.response.status == 422) {

						var obj = error.response.data;
						// console.log(obj);
						for (var key in obj) {
							// make first letter uppercase
							let displayKey = key.charAt(0).toUpperCase() + key.slice(1);
							// replace underscores
							displayKey = displayKey.replace(/_/g, " ");
							// console.log(key); // key1 and etc...
							// console.log(obj[key]); // val1 and etc...
							this.$toasted.error(displayKey + " : " + obj[key]);
						}

					} else if (error.response.status == 405) {
						// validation failed
					}
					// console.log(error.response.data);
					// console.log(error.response.status);
					// console.log(error.response.headers);
				} else if (error.request) {
					// The request was made but no response was received
					// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
					// http.ClientRequest in node.js
					// console.log(error.request);
				} else {
					// Something happened in setting up the request that triggered an Error
					// console.log('Error', error.message);
				}
			})
		},
		ownerSelect(selected) {
			// go get the friggen user
			this.first_name = selected.first_name;
			this.last_name = selected.last_name;
			this.email = selected.email;
			this.userID = selected.id;
			this.$emit('callback', this.userID);
		},
		generateRandomPassword() {
			return Math.random().toString(36).slice(-8);
		},
		changeOwner() {
			this.first_name = "";
			this.last_name = "";
			this.email = "";
			this.userID = 0;
		},
	},
	components: {
		NSearch,
	},
	watch: {
		userID() {
			if (this.userID != 0) {

			}
		},
	},
}
</script>
