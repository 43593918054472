<template>
<div>
	<section class="section section-welcome-message">
		<div class="container">
			<div class="row">
				<div class="col-xs-12 col-sm-9">
					<h1 class="welcome-message">New Page</h1>
				</div>
				<div class="col-xs-12 col-sm-3">
					<br>
					<router-link to="/pages">
						<button>Back</button>
					</router-link>
				</div>
			</div>
		</div>
	</section>
	<section class="section section-new-group">
		<div class="container">
			<div class="row">
				<div class="col-xs-12 col-md-12">
					<div class="form-NewGroup">
						<!-- Standard field input -->
						<fieldset class="field-textinput">
							<label for="name-group">
                                <span>Page Title</span>
                                <hr class="bg-white">
                            </label>
							<input type="text" name="name-group" v-model="title" placeholder="Title">
						</fieldset>

						<fieldset class="field-textinput">
							<label for="name-group">
                                <span>Page Slug</span>
                                <hr class="bg-white">
                            </label>
							<input type="text" name="name-group" v-model="slug" placeholder="Slug">
						</fieldset>

						<!-- Textarea -->
						<fieldset class="field-textarea">
							<label for="description-group">
                                <span>Page description</span>
                                <hr class="bg-white">
                            </label>
							<textarea name="description-group" id="description-group" cols="30" rows="8" v-model="description" placeholder="Description"></textarea>
						</fieldset>

						<fieldset class="field-textarea">
							<label for="description-group">
                                <span>Page Content</span>
                                <hr class="bg-white">
                            </label>
							<quill-editor v-model="content" ref="contentEditor" :options="editorOption" @blur="onEditorBlur($event)" @focus="onEditorFocus($event)" @ready="onEditorReady($event)">
								<div id="toolbar" slot="toolbar">
									<!-- Add a bold button -->

								</div>
							</quill-editor>
						</fieldset>



						<fieldset class="field-textarea">
							<label for="description-group">
                                <span>Preview</span>
                                <hr class="bg-white">
                            </label>
							<h1 v-if="title">{{ title }}</h1>
							<a :href="fullSlug" target="_blank" v-if="slug" class="text-small"><strong>{{ fullSlug }}</strong></a>
							<div v-html="content" class="preview"></div>
						</fieldset>

						<!-- Input with a button next to it -->
						<button class="button-link" @click="save">Save</button>
					</div>
				</div>
			</div>
		</div>
	</section>
</div>
</template>

<script>
export default {
	data() {
		return {
			id: null,
			title: "",
			description: "",
			slug: "",
			content: "",
			editorOption: {
				modules: {
					toolbar: [
						['bold', 'italic', 'underline', 'strike'],
						[{ 'list': 'ordered' }, { 'list': 'bullet' }],
						[{ 	'header': [1, 2, 3, 4, 5, 6, false] }],
						[{
							'color': []
						}, {
							'background': []
						}],
						[{
							'font': []
						}],
						[{
							'align': []
						}],
						['link'],
						['clean']
					]
				}
			}
		}
	},
	methods: {
		save() {
            this.$Progress.start();

            let slug = this.slugify(this.slug);
			axios.post('/api/pages/register', {
					title: this.title,
					description: this.description,
					slug: slug,
					content: this.content,
				})
				.then((response) => {
					// redirect them back
					this.$router.push({
						path: '/pages'
					})
                    this.$Progress.finish();
					this.$toasted.success("Success! Page: " + this.title + " saved.").goAway(6000);
				})
				.catch((error) => {
                    this.$Progress.fail();
					if (error.response) {
						// The request was made and the server responded with a status code
						// this falls out of the range of 2xx
						if (error.response.status == 422) {

							var obj = error.response.data;
							// console.log(obj);
							for (var key in obj) {
								// make first letter uppercase
								let displayKey = key.charAt(0).toUpperCase() + key.slice(1);
								// replace underscores
								displayKey = displayKey.replace(/_/g, " ");

								this.$toasted.error(displayKey + " : " + obj[key]);
							}

						} else if (error.response.status == 405) {

						}
					} else if (error.request) {
						// The request was made but no response was received
						// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
						// http.ClientRequest in node.js
						// console.log(error.request);
					} else {
						// Something happened in setting up the request that triggered an Error
					}
				})
		},
		slugify(text) {
			return text.toString().toLowerCase()
				.replace(/\s+/g, '-') // Replace spaces with -
				.replace(/[^\w\-]+/g, '') // Remove all non-word chars
				.replace(/\-\-+/g, '-') // Replace multiple - with single -
				.replace(/^-+/, '') // Trim - from start of text
				.replace(/-+$/, ''); // Trim - from end of text
		},
		onEditorBlur(editor) {

		},
		onEditorFocus(editor) {

		},
		onEditorReady(editor) {

		},
		onEditorChange({
			editor,
			html,
			text
		}) {
			// console.log('editor change!', editor, html, text)
			this.content = html
		},
	},
	computed: {
		// a computed getter
		fullSlug: function() {
			if (typeof location.origin === 'undefined')
				location.origin = location.protocol + '//' + location.host;

			return location.origin + "/" + this.slugify(this.slug);
		}
	},
	components: {

	}
}
</script>
