<template>
<div>
    <div class="viewing" v-if="viewing">
        {{ this.event.title }} / {{ this.event.date}} / tax date: {{ this.event.tax_date }}
        <button @click.prevent="edit">Edit</button>
    </div>
    <div class="editing" v-else>
        <input type="text" v-model="title"/>
        <textarea name="description" v-model="description"></textarea>
        <input type="date" v-model="date"/>
        <input type="date" v-model="tax_date"/>
        <button @click.prevent="save">Save</button>
    </div>
</div>
</template>

<script>
export default {
    // becuase event is a reserved keyword lets just use ev
	props: ['event','editCallback'],
	data() {
		return {
            id: this.event.id,
            title: this.event.title,
            description: this.event.description,
            date:this.event.date,
            tax_date:this.event.tax_date,
			viewing: true,
		}
	},
	created() {},
	methods: {
        edit(){
            this.viewing = false;
        },
        save(){
            // try and save this to the database first

            // try saving
            axios.put('/api/events/' + this.id, {
                title: this.title,
                description: this.description,
                date: this.date,
                tax_date: this.tax_date,
            })
            .then((response) => {
                // console.log(response);
                this.viewing = true;

                this.$toasted.success("Success! Event: " + this.title + " updated.").goAway(6000);

                this.$emit('editCallback',{
                    id: this.id,
                    title: this.title,
                    description: this.description,
                    date: this.date,
                    tax_date: this.tax_date,
                })

            })
            .catch((error) => {
                if (error.response) {
                    // Unprocessable entity is 422
                    if (error.response.status == 422) {

                        // validation failed
                        var obj = error.response.data;
                        for (var key in obj) {
                            
                            let displayKey = key.charAt(0).toUpperCase() + key.slice(1);
                            // replace underscores
                            displayKey = displayKey.replace(/_/g, " ");

                            this.$toasted.error(displayKey + " : " + obj[key]);
                        }

                    } else if (error.response.status == 405) {
                        // 405 is a method not allowed

                    }
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    // console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    // console.log('Error', error.message);
                }
            })

        },
	}
}
</script>
