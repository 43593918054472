module.exports = {
    routes: [
        { path: '/', component: require('./components/Admin.vue').default },

        { path: '/groups', component: require('./components/Groups/Groups.vue').default },
        { path: '/groups/new', component: require('./components/Groups/NewGroup.vue').default },
        { path: '/groups/:id', component: require('./components/Groups/EditGroup.vue').default },
        { path: '/groups/:id/schedule', component: require('./components/Groups/CreateSchedule.vue').default },
        { path: '/groups/:id/view', component: require('./components/Groups/ViewGroup.vue').default },

        { path: '/events', component: require('./components/Events/Events.vue').default },
        { path: '/events/new', component: require('./components/Events/NewEvent.vue').default },
        { path: '/events/:id', component: require('./components/Events/EditEvent.vue').default },

        { path: '/pages', component: require('./components/Pages/Pages.vue').default },
        { path: '/pages/new', component: require('./components/Pages/NewPage.vue').default },
        { path: '/pages/:id', component: require('./components/Pages/EditPage.vue').default },


        { path: '/participants', component: require('./components/Users/ParticipantsOverview.vue').default },
        { path: '/participants/new', component: require('./components/Users/NewParticipant.vue').default },
        { path: '/participants/archived', component: require('./components/Users/Archived.vue').default },

        { path: '/payments', component: require('./components/Payments/Payments.vue').default },
        { path: '/payments/:user', component: require('./components/Payments/Payments.vue').default },

        { path: '/actions', component: require('./components/Actions.vue').default },
    ],
    groupRoutes: [
        { path: '/', component: require('./components/GroupAdmin.vue').default },
        { path: '/participants/new', component: require('./components/Users/NewParticipant.vue').default },
        // { path: '/:id/schedule', component: require('./components/Groups/CreateSchedule.vue').default, name: 'groupadmin-schedule' },
        { path: '/actions', component: require('./components/Actions.vue').default },
    ],
    meta: {
     progress: {
       func: [
         {call: 'color', modifier: 'temp', argument: '#ffb000'},
         {call: 'fail', modifier: 'temp', argument: '#6e0000'},
         {call: 'location', modifier: 'temp', argument: 'top'},
         {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 400}}
       ]
     }
   }
};
