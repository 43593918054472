<template>
    <div>
        <section class="section section-welcome-message">
            <div class="container">
                <div class="row">
                    <div class="col-xs-12 col-sm-9">
                        <h1 class="welcome-message">Editing Event: {{ parade.title }}</h1>
                    </div>
                    <div class="col-xs-12 col-sm-3">
                        <router-link to="/events">
                            <button>Back</button>
                        </router-link>
                    </div>
                </div>
            </div>
        </section>

        <section class="section section-new-group">
            <div class="container">
                <div class="row">
                    <div class="col-xs-12 col-md-12">
                        <div class="form-NewGroup">
                            <!-- Standard field input -->
                            <fieldset class="field-textinput">
                                <label for="name-group">
                                    <span>Event Name</span>
                                    <hr class="bg-white">
                                </label>
                                <input type="text" name="name-group" v-model="parade.title" placeholder="Name">
                            </fieldset>

                            <fieldset class="field-textinput">
                                <label for="name-group">
                                    <span>Event Date</span>
                                    <hr class="bg-white">
                                </label>
                                <input type="date" name="name-group" v-model="parade.date" placeholder="Name">
                            </fieldset>

                            <fieldset class="field-textinput">
                                <label for="name-group">
                                    <span>Event Tax Date</span>
                                    <hr class="bg-white">
                                </label>
                                <input type="date" name="name-group" v-model="parade.tax_date" placeholder="Name">
                            </fieldset>

                            <!-- Textarea -->
                            <fieldset class="field-textarea">
                                <label for="description-group">
                                    <span>Event Description</span>
                                    <hr class="bg-white">
                                </label>
                                <textarea name="description-group" id="description-group" cols="30" rows="8"  v-model="parade.description" placeholder="Description"></textarea>
                            </fieldset>
                            <br>
                            <!-- Input with a button next to it -->
                            <button class="button-link"  @click="save">Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
	data() {
		return {
            parade:{
                id:0,
            },
			viewing: true,
		}
	},
    created(){
        let id = this.$route.params.id;
        axios.get('/api/events/' + id).then((response) => {
            this.parade = response.data;
        })
    },
	methods: {
        edit(){
            this.viewing = false;
        },
        save(){
            // try and save this to the database first
            this.$Progress.start();
            // try saving
            axios.put('/api/events/' + this.parade.id, {
                title: this.parade.title,
                description: this.parade.description,
                date: this.parade.date,
                tax_date: this.parade.tax_date,
            })
            .then((response) => {
                // console.log(response);
                this.viewing = true;
                this.$Progress.finish();
                this.$toasted.success("Success! Event: " + this.parade.title + " updated.").goAway(6000);

                this.$router.push({ path: '/events/' });

            })
            .catch((error) => {
                this.$Progress.fail();
                if (error.response) {
                    // Unprocessable entity is 422
                    if (error.response.status == 422) {

                        // validation failed
                        var obj = error.response.data;
                        for (var key in obj) {
                            
                            let displayKey = key.charAt(0).toUpperCase() + key.slice(1);
                            // replace underscores
                            displayKey = displayKey.replace(/_/g, " ");

                            this.$toasted.error(displayKey + " : " + obj[key]);
                        }

                    } else if (error.response.status == 405) {
                        // 405 is a method not allowed

                    }
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                } else {
                    // Something happened in setting up the request that triggered an Error
                }
            })

        },
	}
}
</script>
