<template>
    <div>
        <section class="section section-welcome-message"  v-if="group">
            <div class="container">
                <div class="row">
                    <div class="col-xs-12 col-sm-9">
                        <h1 class="welcome-message">Group: {{ group.name }}</h1>

                    </div>
                    <div class="col-xs-12 col-sm-3">
                        <button @click="back">Back</button>
                        <router-link :to="{ path:'/groups/' + group.id }">
                            <button><i class="icon ion-edit"></i></button>
                        </router-link>
                    </div>
                </div>
            </div>
        </section>

        <section class="section section-timeline"  v-if="group">
            <div class="container">
                <div class="row">
                    <div class="col-xs-12">
                        <h2>Going to {{ group.event_title.title }}</h2>
                    </div>
                </div>
                <div class="col-xs-12 col-md-12">
                    <GroupSchedule :group="group"></GroupSchedule>
                </div>

            </div>
        </section>

        <ParticipantList  v-if="group" :fromGroup="group.id"></ParticipantList>
    </div>
</template>

<script>
import ParticipantList from '../Users/ParticipantList.vue';
import GroupSchedule from './GroupSchedule.vue';

export default {
    data(){
        return{
            group:null,
        }
    },
    created(){
        let id = this.$route.params.id;
        // load data about this particular group
        axios.get('/api/groups/' + id).then((response) => {
            this.group = response.data;
        })
    },
	mounted() {
		// console.log('Component mounted.')
	},
    methods:{
        back(){
            this.$router.go(-1);
        }
    },
    components: {
        ParticipantList,
        GroupSchedule,
    }
}
</script>
