<template>
<div>
    <section class="section section-welcome-message">
        <div class="container">
            <div class="row">
                <div class="col-xs-12 col-sm-9">
                    <h1 class="welcome-message">Page: {{ page.title }}</h1>
                </div>
                <div class="col-xs-12 col-sm-3">
                    <router-link to="/pages">
                        <button>Back</button>
                    </router-link>
                </div>
            </div>
        </div>
    </section>
    <section class="section section-new-group">
        <div class="container">
            <div class="row">
                <div class="col-xs-12 col-md-12">
                    <div class="form-NewGroup">
                        <!-- Standard field input -->
                        <fieldset class="field-textinput">
                            <label for="name-group">
                                <span>Page Title</span>
                                <hr class="bg-white">
                            </label>
                            <input type="text" name="name-group" v-model="page.title" placeholder="Title">
                        </fieldset>

                        <fieldset class="field-textinput">
                            <label for="name-group">
                                <span>Page Slug</span>
                                <hr class="bg-white">
                            </label>
                            <input type="text" name="name-group" v-model="page.slug" placeholder="Slug">
                        </fieldset>
                        <!-- Textarea -->
                        <fieldset class="field-textarea">
                            <label for="description-group">
                                <span>Page description</span>
                                <hr class="bg-white">
                            </label>
                            <textarea name="description-group" id="description-group" cols="30" rows="8"  v-model="page.description" placeholder="Description"></textarea>
                        </fieldset>

                        <fieldset class="field-textarea">
                            <label for="description-group">
                                <span>Page Content</span>
                                <hr class="bg-white">
                            </label>
                            <quill-editor v-model="page.content" ref="contentEditor" :options="editorOption" @blur="onEditorBlur($event)" @focus="onEditorFocus($event)" @ready="onEditorReady($event)">
                                <div id="toolbar" slot="toolbar">
                                    <!-- Add a bold button -->

                                </div>
                            </quill-editor>
                        </fieldset>



                        <fieldset class="field-textarea">
                            <label for="description-group">
                                <span>Preview</span>
                                <hr class="bg-white">
                            </label>
                            <h1 v-if="page.title">{{ page.title }}</h1>
                            <a :href="fullSlug" target="_blank" v-if="page.slug" class="text-small"><strong>{{ fullSlug }}</strong></a>
                            <br><br>
                            <div v-html="page.content" class="preview"></div>
                        </fieldset>

                        <!-- Input with a button next to it -->
                        <button class="button-link"  @click="save">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
</template>

<script>
export default {
    created(){
        let id = this.$route.params.id;
        axios.get('/api/pages/' + id).then((response) => {
            this.page = response.data;
        })
    },
	data() {
		return {
            page: {
                title: "",
    			description: "",
    			slug: "",
    			content: "",
            },
            editorOption: {
                modules: {
                    toolbar: [
                        ['bold', 'italic', 'underline', 'strike'],
                        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                        [{ 	'header': [1, 2, 3, 4, 5, 6, false] }],
                        [{
                            'color': []
                        }, {
                            'background': []
                        }],
                        [{
                            'font': []
                        }],
                        [{
                            'align': []
                        }],
                        ['link'],
                        ['clean']
                    ]
                }
            },
			id: this.$route.params.id,
		}
	},
	methods: {
		save() {
            this.$Progress.start();
            let slug = this.slugify(this.page.slug);
			axios.put('/api/pages/' + this.id, {
					title: this.page.title,
					description: this.page.description,
					slug: slug,
					content: this.page.content,
				})
				.then((response) => {
                    // redirect them back
                    this.$router.push({ path: '/pages' })
                    this.$toasted.success("Success! Page: " + this.page.title + " updated.").goAway(6000);
                    this.$Progress.finish();
				})
                .catch((error) => {
                    this.$Progress.fail();
                    if (error.response) {
                        // console.log(error.response.data + " " + error.response.status);

                        // Unprocessable entity is 422
                        if (error.response.status == 422) {

                            // validation failed
                            var obj = error.response.data;
                            for (var key in obj) {
                                // TODO PERHAPS MOVE THIS TO THE LARAVEL SIDE?
                                let displayKey = key.charAt(0).toUpperCase() + key.slice(1);
                                // replace underscores
                                displayKey = displayKey.replace(/_/g, " ");

                                this.$toasted.error(displayKey + " : " + obj[key]);
                            }

                        } else if (error.response.status == 405) {
                            // 405 is a method not allowed

                        }
                    } else if (error.request) {
                        // The request was made but no response was received
                        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                        // http.ClientRequest in node.js
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        // console.log('Error', error.message);
                    }
                })
		},
        slugify(text) {
            return text.toString().toLowerCase()
                .replace(/\s+/g, '-') // Replace spaces with -
                .replace(/[^\w\-]+/g, '') // Remove all non-word chars
                .replace(/\-\-+/g, '-') // Replace multiple - with single -
                .replace(/^-+/, '') // Trim - from start of text
                .replace(/-+$/, ''); // Trim - from end of text
        },
        onEditorBlur(editor) {},
		onEditorFocus(editor) {},
		onEditorReady(editor) {},
		onEditorChange({
			editor,
			html,
			text
		}) {
			// console.log('editor change!', editor, html, text)
			this.content = html
		},
	},
    computed: {
        // a computed getter
        fullSlug: function() {
            if (typeof location.origin === 'undefined')
                location.origin = location.protocol + '//' + location.host;

            return location.origin + "/" + this.slugify(this.page.slug);
        }
    },
}
</script>
