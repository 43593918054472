<template>
<div>
    <div class="viewing">
        {{ this.schedule.date }} - {{ this.schedule.amount_due }}
    </div>

    <div class="editing">

    </div>

</div>
</template>

<script>

export default {
	props: ['schedule'],
    data() {
		return {
            date:this.schedule.date,
            amount_due:this.schedule.amount_due,
            group: this.schedule.group,
		}
	},
}
</script>
