<template>
<div>
	<div class="col-xs-12 col-sm-6">
		<button @click="toggleVisibility" class="no-border">
            <span v-if="!open">Transfer Funds</span>
            <span v-if="open">Close Transfer Funds</span>
        </button>
	</div>
    <div class="col-xs-12">
        <div class="row">
            <div class="col-xs-12" v-if="open">
                <div class="transfer-table bg-white">
                    <div class="row">
                        <div class="col-xs-12 col-md-4">
                            <div class="table-from">
                                <p class="text-bold text-uppercase text-red">From</p>
                                <NSearch :exclude="secondPerson" :type="'users'" :placeholder="'Search for user'" :model="'id'" :displays="['first_name','last_name','total_paid','group.name']" @firstPerson="onFirstSelect" :firstPerson="true"></NSearch>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-4">
                            <div class="table-to">
                                <p class="text-bold text-uppercase text-blue">To</p>
                                <NSearch  :exclude="firstPerson" :type="'users'" :placeholder="'Search for user'" :model="'id'" :displays="['first_name','last_name','total_paid','group.name']" @secondPerson="onSecondSelect" :secondPerson="true"></NSearch>

                            </div>
                        </div>
                        <div class="col-xs-12 col-md-4">
                            <div class="receipt">
                                <p class="text-bold text-uppercase">Receipt</p>
                                <p>Transferring funds</p>
                                <div class="transfer-details">
                                    <p class="transfer-from">FROM: <span v-if="firstPerson">{{ firstPerson.first_name }}</span></p>
                                    <p class="transfer-to">TO: <span v-if="secondPerson">{{ secondPerson.first_name }}</span></p>
                                    <p class="transfer-amount">Amount: <span v-if="firstPerson && secondPerson"><vue-numeric currency="$" separator="," v-model="firstPerson.total_paid" read-only :minus="true" ></vue-numeric></span></p>
                                </div>
                                <p class="transfer-to-total">Another name total: <span v-if="firstPerson && secondPerson">${{ (secondPerson.total_paid + firstPerson.total_paid).toLocaleString() }}</span></p>
                                <button @click.prevent="transfer">TRANSFER FUNDS</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>


</div>
</template>

<script>
import Search from './Search.vue';
import NSearch from './NSearch.vue';
import VueNumeric from 'vue-numeric';

export default {
	props: ['participants', 'fetchAll'],
	data() {
		return {
			open: false,
			firstPerson: null,
			secondPerson: null,
			filteredItems: this.participants,
		}
	},
	ready() {
		// this.passedthroughvar can now be used for later requests to the backend and such
		this.filteredItems = participants;
	},
	methods: {
		onClickButton(event) {
			this.$emit('clicked', 'someValue')
		},
		transfer() {
            this.$Progress.start();
			axios.post('/api/payments/transfer', {
					userA: this.firstPerson.id,
					userB: this.secondPerson.id,
				})
				.then((response) => {
					// console.log(response);
					this.open = false;
					this.$toasted.success("Success! Transferred $" + (this.secondPerson.total_paid + this.firstPerson.total_paid).toLocaleString() + " from: " + this.firstPerson.first_name + " to: " + this.secondPerson.first_name).goAway(6000);
					// update the list above to reflect change
					this.$emit('callback');
                    this.$Progress.finish();
				})
				.catch((error) => {
                    this.$Progress.fail();
                    if (error.response) {
                        if (error.response.status == 422) {
                            // validation failed
                            var obj = error.response.data;
                            for (var key in obj) {
                                this.$toasted.error(key + " : " + obj[key]);
                            }
                        }
                    }
				});
		},
		updateSearch(items) {
			this.filteredItems = items;
		},
		onFirstSelect(selected) {
			this.firstPerson = selected;
		},
		onSecondSelect(selected) {
			this.secondPerson = selected;
		},
		toggleVisibility() {
			this.open = !this.open;
		},
	},
	watch: {
		participants(search) {
			if (this.participants) {
				this.filteredItems = this.participants;
			}
		}
	},
	components: {
		Search,
		NSearch,
		VueNumeric
	}
}
</script>
