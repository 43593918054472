<template>
<div>
	<section class="section section-welcome-message">
		<div class="container">
			<div class="row">
				<div class="col-xs-12">
					<h1 class="welcome-message">Managing Pages</h1>
				</div>
			</div>
		</div>
	</section>
    <section class="section">
        <div class="container">
            <div class="row">

                <div class="col-sm-12 col-block-5">
                    <router-link to="/">
                        <button class="dashboard-button">
                            <i class="icon ion-speedometer"></i>
                            <p>Dashboard</p>
                        </button>
                    </router-link>
                </div>

                <div class="col-sm-6 col-block-5">
                    <router-link to="/events">
                        <button class="dashboard-button">
                            <i class="icon ion-calendar"></i>
                            <p>Parades</p>
                        </button>
                    </router-link>
                </div>

                <div class="col-sm-6 col-block-5">
                    <router-link to="/groups">
                        <button class="dashboard-button">
                            <i class="icon ion-ios-people"></i>
                            <p>Groups</p>
                        </button>
                    </router-link>
                </div>

                <div class="col-sm-6 col-block-5">
                    <router-link to="/payments">
                        <button class="dashboard-button">
                            <i class="icon ion-social-usd-outline"></i>
                            <p>Payments</p>
                        </button>
                    </router-link>
                </div>

                <div class="col-sm-6 col-block-5">
                    <router-link to="/pages">
                        <button class="dashboard-button active">
                            <i class="icon ion-document"></i>
                            <p>Pages</p>
                        </button>
                    </router-link>
                </div>
            </div>
        </div>
    </section>


	<section class="section section-event-summary">
		<div class="container">
			<div class="row">
				<div class="col-xs-12 col-md-12">
                    <router-link to="/pages/new">
                        <button>Create new page</button>
                    </router-link>
					<div class="row group-summary-row" v-for="page in pages">
						<div class="col-xs-12 col-sm-8 col-md-9">
							<div class="group-summary-inner-wrapper">
								<div class="group-summary-details">
									<p class="group-name">
										{{ page.title }}
									</p>
									<p class="group-payment-progress">
                                        <a :href="fullSlug(page.slug)" target="_blank" v-if="page.slug" class="text-small"><strong>{{ fullSlug(page.slug) }}</strong></a>
                                        <br>
									</p>
								</div>
								<div class="group-summary-description">
                                    {{ page.description }}
								</div>
							</div>
						</div>
						<div class="col-xs-12 col-sm-4 col-md-3">
							<div class="group-summary-options">
								<router-link :to="{ path:'/pages/' + page.id }">
									<button><i class="icon ion-edit" /> Edit</button>
								</router-link>
								<br>
								<button class="scheme-red" @click.prevent="delPage(page)"><i class="icon ion-trash-a" /> Delete</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</div>
</template>

<script>
import Page from './Page.vue';

export default {
	data() {
		return {
			creating: false,
			page: {
				title: null,
				description: null,
				slug: null,
				content: null,
			},
			pages: [],
		}
	},
	created() {
		this.fetchAll();
	},
	methods: {
		fetchAll() {
			axios.get('/api/pages', {}).then((response) => {
				// check if 401
				this.pages = response.data;
			})
		},
		create() {
			// console.log("trying to create");
			this.creating = true;
		},
		save: function() {

			// save the new participant
			axios.post('/api/pages/', {
					title: this.page.title,
					description: this.page.description,
					slug: this.page.slug,
					content: this.page.content,
				})
				.then((response) => {
					// reset the participant
					this.page = {
						title: null,
						description: null,
						slug: null,
						content: null,
					};
					this.creating = false;
					this.pages = response.data;
				})
				.catch((error) => {
					if (error.response) {
						// The request was made and the server responded with a status code
						// this falls out of the range of 2xx
						if (error.response.status == 422) {
							// validation failed
						} else if (error.response.status == 405) {

						}
					}
				})
		},
		delPage(page) {
			var index = this.pages.indexOf(page);
			if (index > -1) {
				this.pages.splice(index, 1);
			}
			axios.delete('/api/pages/' + page.id, {
					page,
				})
				.then((response) => {
					var index = this.pages.indexOf(page);
					if (index > -1) {
						this.pages.splice(index, 1);
					}
					this.$toasted.error("Success! Page: '" + page.title + "' deleted.").goAway(6000);
				})
				.catch(function(error) {

				});
		},
		update(page) {
			// Update the page with the callback
			for (var i = 0; i < this.pages.length; i++) {
				if (this.pages[i].id == page.id) {
					this.pages[i].title = page.title;
					this.pages[i].description = page.description;
					this.pages[i].slug = page.slug;
					this.pages[i].content = page.content;
				}
			}
		},
        slugify(text) {
			return text.toString().toLowerCase()
				.replace(/\s+/g, '-') // Replace spaces with -
				.replace(/[^\w\-]+/g, '') // Remove all non-word chars
				.replace(/\-\-+/g, '-') // Replace multiple - with single -
				.replace(/^-+/, '') // Trim - from start of text
				.replace(/-+$/, ''); // Trim - from end of text
		},
        fullSlug(text){
            if (typeof location.origin === 'undefined')
                location.origin = location.protocol + '//' + location.host;

            return location.origin + "/" + this.slugify(text);
        }
	},
	components: {
		Page,
	}
}
</script>
