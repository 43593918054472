<template>
<div>
	<div class="row progress-row">
		<div class="col-xs-12" v-if="label">

			<p class="group-name">Schedule for: {{ this.group.name }}</p>
		</div>
		<div class="col-xs-12" >
			<div class="timeline-wrapper" ref="timeline" style="position:relative" >
				<!-- Start date -->

				<span class="start-date base-date">{{ this.startDate | moment("DD-MM-YYYY") }}</span>
				<div class="progress bg-purple">
                    <resize-observer @notify="handleResize" />
					<div v-if="loaded" class="progress-bar bg-yellow" role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100" style="width:70%;" :style="{ width: differenceToday + '%' }">
						<!-- TODO REMOVE THIS -->
						<span class="pull-right" style="color: #333;padding-right: 0px;font-size: 12px;">TODAY</span>
					</div>
				</div>
				<div v-if="loaded" v-for="schedule in schedules" >
					<div class="timeline-marker" timeline-pos="0" :style="{ left: getDifference(schedule.date) + 'px' }">

						<div class="marker" v-bind:class="{ clickable: (homepage && !schedule.paid),active: (checkInArray(schedule)), paid: schedule.paid }" @click.prevent="toggleMarker(schedule)">
							<vue-numeric currency="$" separator="," v-model="schedule.display" read-only></vue-numeric>
						</div>
						<div class="date">{{ schedule.date | moment("DD/MM/YYYY") }}</div>
					</div>
				</div>
				<span class="end-date base-date">{{ this.endDate | moment("DD-MM-YYYY") }}</span>
			</div>
		</div>
	</div>

	<div class="row" v-if="this.homepage">
		<div class="col-xs-12">
			<div class="scheme-red payment-amount">
				<p class="scheme-red text-black text-center">Click the markers or type in an amount to pay:</p>
				<!-- <p class="scheme-red text-black text-center" v-if="selected.length > 0">Paying {{ selected.length }} payments</p> -->

				<fieldset class="field-textinput centered-input">
					<vue-numeric currency="$" separator="," v-model="total"></vue-numeric>
				</fieldset>

			</div>
		</div>
	</div>
</div>
</template>

<script>
// Load in moment
const moment = require('moment');
import VueNumeric from 'vue-numeric';
import {
	ResizeObserver
} from 'vue-resize/dist/vue-resize'
export default {
	props: ['group', 'homepage', 'exclude', 'totalPaid','label'],
	created() {
        // Fetch the schedules
		axios.get('/api/schedules/' + this.group.id).then((response) => {
            this.schedules = response.data;

            if (this.schedules.length !== 0) {

                this.startDate = this.schedules[0].date;

                for (let e = 0; e < this.schedules.length; e++) {
                    // for each schedule
                    let schedule = this.schedules[e];

                    // check the schedule against the exclusion list
                    if (this.exclude != null && this.exclude.length > 0) {
                        for (let i = 0; i < this.exclude.length; i++) {
                            let exclusion = this.exclude[i];
                            // console.log(exclusion);

                            // check against the marker and it's id
                            if (schedule.id == exclusion) {
                                this.schedules[e].paid = true;
                            }
                        }
                    }
                    // set each schedule to have a display number
                    this.schedules[e].amount_due = parseFloat(this.schedules[e].amount_due);

                    this.schedules[e].display = this.schedules[e].amount_due;
                }

                // now that we have the schedules we can check how much we have paid
                // and how much needs to be removed from the last marker
                let total = 0, last = 0;
                let remainder = 0;
                if(this.totalPaid > 0){
                    for(let i = 0; i < this.schedules.length; i++){
                        let schedule = this.schedules[i];

                        if(!this.inExclusion(schedule)){
                            if((total+schedule.amount_due) > this.totalPaid){
                                // whatver goes over the totalPaid is the last marker
                                last = i;
                                remainder = (total+schedule.amount_due) - this.totalPaid;
                                // console.log("LAST ONE IS " + i);
                                break;
                            }else{
                                // console.log("ADDING TO TOTAL");
                                total+= schedule.amount_due;
                            }
                        }else{
                            total+= schedule.amount_due;
                        }
                    }
                    // console.log("REMAINDER " + remainder);
                    // console.log("TOTAL " + total);


                    // We have the last marker,
                    // Subtract from the marker's display amount

                    this.schedules[last].amount_due = remainder;
                    this.schedules[last].display = this.schedules[last].amount_due;
                    // console.log(this.schedules[last]);
                }



                // load the schedules that are due today
                axios.get('api/schedules/due/today').then((response) => {
                    this.dueSchedules = response.data;
                });

                // Find the start date for payments, when the group was created.
                var a = moment(this.startDate);

                // Find the event associated with the group
                axios.get('/api/events/' + this.group.event_id).then((response) => {
                    // Set the end date to be the event date
                    this.endDate = response.data.date;

                    // once we know the two dates we can set the interval for percentage progress
                    var b = moment(response.data.date);

                    // Get the difference between the two dates
                    var diff = b.diff(a, 'days');
                    this.diff = diff;
                    // // console.log("Diff: " + this.diff);

                    // Get the timeline div
                    var timelineDiv = this.$refs.timeline;

                    // Get the width of the timeline div
                    let width = this.$refs.timeline.clientWidth;
                    // // console.log("Width: " + width);

                    // Calculate the interval for the width
                    this.interval = (width / this.diff);
                    // // console.log("Interval: " + this.interval);

                    this.loaded = true;
                });
            }

		})

	},
	data() {
		return {
			today: moment(),
			diff: null,
			interval: null,
			schedules: null,
			startDate: null, // the group creation date
			endDate: null, // the event date
			selected: [],
			total: 0,
			dueSchedules: [],
			oldTotal: 0,
            saveTimeout: null,
            loaded:false,
		}
	},
	watch: {
		total(value) {
            // Reset the display values of all the markers
            this.resetDisplays();

            // Get the total from totalSelected
            let totalSelected = this.totalSelected();

            if(value != totalSelected){
                // console.log("Not the marker picker value: " + value);
                // console.log("Marker picker value: " + totalSelected);

                if(value > this.oldTotal){
                    // console.log("Value has increased");
                }else if(value < this.oldTotal){

                    // if the value decreases remove the markers
                    for (let i = 0; i < this.schedules.length; i++) {
                        let marker = this.schedules[i];

                        if (!this.inExclusion(marker)) {
                            // we know its not in the exclusion array
                            if(this.checkInArray(marker)){
                                this.removeFromBasket(marker);
                            }
                        }
                    }
                    // console.log("Value has decreased");
                }

                // Then this value has been typed
                // Not selected via the marker picker

                // check if it has gone up or down
                // and
                // Loop through each schedule that we can use
                // and start creating a total
                // once we reach the 'value', find the last one in the list
                let tempTotal = 0;
                let last = 0;
                for (let i = 0; i < this.schedules.length; i++) {
                    let marker = this.schedules[i];

                    if (!this.inExclusion(marker)) {
                        // we know its not in the exclusion array

                        if( (tempTotal + marker.amount_due ) > this.total){
                            // the one after is the next one, we know the last one
                            last = i;

                            // Calculate the remainder for the last marker
                            this.schedules[last].display = this.total - (tempTotal+marker.amount_due);

                            break;
                        }else{
                            tempTotal += marker.amount_due;
                            if(!this.checkInArray(marker)){
                                this.addToBasket(marker);
                            }

                        }
                    }
                }
            }

            this.updateSession();
            if(this.oldTotal != null){
                this.oldTotal = this.total;
            }else{
                this.oldTotal = 0;
            }

		}
	},
	computed: {
		differenceToday() {
            if(this.endDate){
                let diff = moment(this.endDate).diff(moment(), 'days');
                // // console.log("Diff and interval: " + diff + " - " + (diff*this.interval));

                // Get the timeline div
    			var timelineDiv = this.$refs.timeline;

                // Get the width of the timeline div
    			let width = this.$refs.timeline.clientWidth;
                // // console.log("Width: " + width);

                // now calc the difference as a percentage from the total width/
                // this is happy enough to retrieve pixel values but cannot be used as a percentage
                let percentage = 100 - (((diff*this.interval) / width) * 100);
                // // console.log("PERECENTAGE " + percentage);

                return percentage;
                return diff * this.interval;
            }else{
                return 0;
            }

		},
	},
	methods: {
		totalSelected() {
			let total = 0;
			for (let i = 0; i < this.selected.length; i++) {
				total += parseFloat(this.selected[i].amount_due);
			}
			return total;
		},
		getDifference(date) {
			let a = moment(this.startDate, "YYYY-MM-DD HH:mm:ss");
			let b = moment(date, "YYYY-MM-DD");
			// // console.log(b.diff(a, 'days'));
			return (b.diff(a, 'days')) * this.interval;
		},
		handleResize() {
            // console.log("Diff: " + this.diff);

            // Get the timeline div
            var timelineDiv = this.$refs.timeline;

            // Get the width of the timeline div
            let width = this.$refs.timeline.clientWidth;
            // console.log("Width: " + width);

            // Calculate the interval for the width
            this.interval = (width / this.diff);
            // console.log("Interval: " + this.interval);
		},
		toggleMarker(marker) {
			if (this.homepage) {
				// toggle depending on whether it is in the array or not
				if (this.checkInArray(marker)) {
					// remove from array
					this.removeFromBasket(marker);
                    this.total = this.totalSelected();
				} else {
					this.addToBasket(marker);
                    this.total = this.totalSelected();
				}
			}
		},
		removeFromBasket(marker) {
			let i = this.getIndexOf(marker);
			if (i != -1) {
                // find any markers in the future and de-select them
                this.selected.splice(i, 1);
                this.adjustMarkers(true,marker.date);

				this.updateSession();
			}
		},
        // only select markers that are either before or after a selectedDate
        adjustMarkers(past, selectedDate = moment(selectedDate)){
            // if schedule dates are in the past compared to a selectedDate
            if(past){
                for (let i = 0; i < this.schedules.length; i++) {
                    let momentConversion = moment(this.schedules[i].date);

                    let diff = momentConversion.diff(selectedDate);

                    if (diff > 0 && this.checkInArray(this.schedules[i])) {
                        this.removeFromBasket(this.schedules[i]);
                    }
                }
            }else{
                for (let i = 0; i < this.schedules.length; i++) {
                    let momentConversion = moment(this.schedules[i].date);

                    let diff = momentConversion.diff(selectedDate);
                    if (diff < 0 && !this.checkInArray(this.schedules[i])) {
                        this.addToBasket(this.schedules[i]);
                    }
                }
            }
        },
		addToBasket(marker) {
			if (this.homepage) {
				// if it is not in the exclusion list
				if (this.exclude != null && this.exclude.length > 0) {
					for (let i = 0; i < this.exclude.length; i++) {
						let exclusion = this.exclude[i];
						// console.log(exclusion);

						// check against the marker and it's id
						if (marker.id == exclusion) {
							// break here
							return false;
						}
					}
				}

				// add to the currently selected markers
                this.adjustMarkers(false,marker.date);
            	this.selected.push(marker);

				this.updateSession();
			}
		},
		getIndexOf(marker) {
			for (let i = 0; i < this.selected.length; i++) {
				if (marker.id == this.selected[i].id) {
					return i;
				}
			}
			return -1;
		},
		checkInArray(marker) {
			if (this.selected != null && this.selected.length > 0) {
				if (this.getIndexOf(marker) != -1) {
					return true;
				} else {
					return false;
				}
			} else {
				return false;
			}
		},
		updateSession() {
            clearTimeout(this.saveTimeout);
            this.saveTimeout = setTimeout(() =>{
                // console.log("hello");
                axios.post('/api/payments/set/amount', {
                    amount: this.total,
                });
            }, 400)
		},
        resetDisplays(){
            for(let i = 0; i < this.schedules.length; i++){
                this.schedules[i].display = this.schedules[i].amount_due;
            }
        },
        inExclusion(marker){
            if (this.exclude != null && this.exclude.length > 0) {
                for (let e = 0; e < this.exclude.length; e++) {
                    let exc = this.exclude[e];
                    if (exc === marker.id) {
                        return true;
                    }
                }
            }
            return false;
        },
	},
	components: {
		VueNumeric,
		ResizeObserver
	}
}
</script>
