<template>
    <div>
        <section class="section section-welcome-message">
            <div class="container">
                <div class="row">
                    <div class="col-xs-12">
                        <h1 class="welcome-message">Managing Groups</h1>
                    </div>
                </div>
            </div>
        </section>

        <section class="section">
            <div class="container">
                <div class="row">

                    <div class="col-sm-12 col-block-5">
                        <router-link to="/">
                            <button class="dashboard-button">
                                <i class="icon ion-speedometer"></i>
                                <p>Dashboard</p>
                            </button>
                        </router-link>
                    </div>

                    <div class="col-sm-6 col-block-5">
                        <router-link to="/events">
                            <button class="dashboard-button">
                                <i class="icon ion-calendar"></i>
                                <p>Parades</p>
                            </button>
                        </router-link>
                    </div>

                    <div class="col-sm-6 col-block-5">
                        <router-link to="/groups">
                            <button class="dashboard-button active">
                                <i class="icon ion-ios-people"></i>
                                <p>Groups</p>
                            </button>
                        </router-link>
                    </div>

                    <div class="col-sm-6 col-block-5">
                        <router-link to="/payments">
                            <button class="dashboard-button">
                                <i class="icon ion-social-usd-outline"></i>
                                <p>Payments</p>
                            </button>
                        </router-link>
                    </div>

                    <div class="col-sm-6 col-block-5">
                        <router-link to="/pages">
                            <button class="dashboard-button">
                                <i class="icon ion-document"></i>
                                <p>Pages</p>
                            </button>
                        </router-link>
                    </div>
                </div>
            </div>
        </section>

        <section class="section section-group-summary">
            <div class="container">
                <div class="row">
                    <div class="col-xs-12 col-md-12">
                        <router-link to="/groups/new">
                            <button>Create new group</button>
                        </router-link>

                        <!-- Normal groups rendered here -->
                        <div class="row group-summary-row" v-for="group in groups">
                            <div class="col-xs-12 col-sm-8 col-md-9">
                                <div class="group-summary-inner-wrapper">
                                    <div class="group-summary-details">
                                        <p class="group-name">
                                            {{ group.name }}
                                        </p>
                                        <p class="group-payment-progress">
										<span class="group-summary-percent-paid">
                                        {{ group.totalPercentagePaid }} %
                                    </span> Fully Paid
                                        </p>
                                    </div>
                                    <div class="group-summary-description">
                                        <p><strong>Going to {{ group.event_title.title }}</strong></p>
                                        <p>{{ group.description }}</p>
                                    </div>
                                </div>

                            </div>
                            <div class="col-xs-12 col-sm-4 col-md-3">
                                <div class="group-summary-options">
                                    <router-link :to="{ path:'/groups/' + group.id + '/view'}">
                                        <button class="scheme-green"><i class="icon ion-eye"></i> View</button>
                                    </router-link>

                                    <router-link :to="{ path:'/groups/' + group.id }">
                                        <button><i class="icon ion-edit"></i> Edit</button>
                                    </router-link>

                                    <router-link :to="{ path:'/groups/' + group.id + '/schedule'}">
                                        <button><i class="icon ion-ios-time-outline"></i> Schedule</button>
                                    </router-link>
                                </div>
                            </div>

                            <div class="row">

                                <div class="col-xs-12">
                                    <hr>
                                </div>

                            </div>

                        </div>
                        <div class="row">

                            <div class="col-xs-12">

                                <br><br><br><br>
                                <p class="text-small">Archived Groups:</p>
                                <hr>
                                <br><br><br><br>
                            </div>


                        </div>
                        <div class="row group-summary-row" v-for="group in archivedGroups">
                            <div class="col-xs-12 col-sm-8 col-md-9">
                                <div class="group-summary-inner-wrapper">
                                    <div class="group-summary-details">
                                        <p class="group-name">
                                            {{ group.name }}
                                        </p>
                                        <p class="group-payment-progress">
                                        <span class="group-summary-percent-paid">
                                        {{ group.totalPercentagePaid }} %
                                    </span> Fully Paid
                                        </p>
                                    </div>
                                    <div class="group-summary-description">
                                        <p><strong>Going to {{ group.event_title.title }}</strong></p>
                                        <p>{{ group.description }}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-4 col-md-3">
                                <div class="group-summary-options">
                                    <router-link :to="{ path:'/groups/' + group.id + '/view'}">
                                        <button class="scheme-green"><i class="icon ion-eye"></i> View</button>
                                    </router-link>

                                    <router-link :to="{ path:'/groups/' + group.id + '/schedule'}">
                                        <button><i class="icon ion-ios-time-outline"></i> Schedule</button>
                                    </router-link>
                                    <button class="scheme-red" @click.prevent="delGroup(group)"><i
                                            class="icon ion-trash-a"></i> Delete
                                    </button>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import Schedules from '../PaymentSchedule/Schedules.vue';
    import NSearch from '../NSearch.vue';

    export default {
        data() {
            return {
                creating: false,
                group: {
                    name: null,
                    description: null,
                    owner_id: null,
                    event_id: null,
                    fee: null,
                },
                groups: [],
                archivedGroups: [],
            }
        },
        created() {
            this.fetchAll();
        },
        methods: {
            fetchAll() {
                axios.get('/api/groups').then((response) => {
                    this.groups = response.data;
                })
                axios.get('/api/archived/groups').then((response) => {
                    this.archivedGroups = response.data;
                })
            },
            create() {
                this.creating = true;
            },
            delGroup(group) {
                axios.delete('/api/groups/' + group.id, {
                    group,
                })
                    .then((response) => {
                        var index = this.groups.indexOf(group);
                        if (index > -1) {
                            this.groups.splice(index, 1);
                        }
                        this.$toasted.error("Success! Group: '" + group.name + "' deleted.").goAway(6000);
                    })
                    .catch((error) => {
                        var index = this.groups.indexOf(group);
                        if (index > -1) {
                            this.groups.splice(index, 1);
                        }
                    });
            },
            update(group) {
                // Update the page with the callback
                for (var i = 0; i < this.groups.length; i++) {
                    if (this.groups[i].id == group.id) {
                        this.groups[i].name = group.name;
                        this.groups[i].description = group.description;
                        this.groups[i].owner_id = group.owner_id;
                        this.groups[i].event_id = group.event_id;
                        this.groups[i].fee = group.fee;
                    }
                }
            },
            onEventSelect(selected) {
                this.group.event_id = selected.id;
            },
            onOwnerSelect(selected) {
                this.group.owner_id = selected.id;
            },
        },
        components: {
            Schedules,
            NSearch,
        }
    }
</script>
