<template>
<div class="good-table">
	<div class="table-header clearfix">
		<h2 v-if="title" class="table-title pull-left">{{title}}</h2>
		<div class="actions pull-right">
		</div>
	</div>
	<table ref="table" :class="styleClass">
		<thead>
			<tr v-if="globalSearch && externalSearchQuery == null">
				<td :colspan="lineNumbers ? columns.length + 1: columns.length">
					<div class="global-search">
						<span class="global-search-icon">
                <img src="search_icon.png" alt="Search Icon" />
              </span>
						<input type="text" class="textinput global-search-input" :placeholder="globalSearchPlaceholder" v-model="globalSearchTerm" @keyup.enter="searchTable()" />
					</div>
				</td>
			</tr>
			<tr>
				<th v-if="lineNumbers" class="line-numbers"></th>
				<th v-for="(column, index) in columns" @click="sort(index)" :class="columnHeaderClass(column, index)" :style="{width: column.width ? column.width : 'auto'}" v-if="!column.hidden">
					<span>{{column.label}}</span>
				</th>
				<slot name="thead-tr"></slot>
                <th v-if="actions != undefined && actions.length > 0">
                </th>
			</tr>
			<tr v-if="hasFilterRow">
				<th v-if="lineNumbers"></th>
				<th v-for="(column, index) in columns">
					<input v-if="column.filterable" type="text" class="textinput" v-bind:placeholder="'Filter ' + column.label" v-bind:value="columnFilters[column.field]" v-on:input="updateFilters(column, $event.target.value)">
				</th>
                <th v-if="actions != undefined && actions.length > 0"></th>
			</tr>
		</thead>

		<tbody>

			<tr v-for="(row, index) in paginated" :class="{clickable: onClick,yellow: (row.colour === 'yellow'),red: (row.colour === 'red') }" @click="click(row, index)">

				<th v-if="lineNumbers" class="line-numbers">{{ getCurrentIndex(index) }}</th>
				<slot name="table-row" :row="row" :index="index">
					<td v-for="(column, i) in columns" :class="getDataStyle(i, 'td')" v-if="!column.hidden">
                        <!-- Check if this is the active row -->
                        <!-- Render the input field instead if possible -->
                        <span v-if="isActiveEdit(row,index)  && column.editable">
                            <input type="text" class="text-black" v-model="active.row[column.field]">
                        </span>

						<span v-if="!isActiveEdit(row,index) && !column.html && column.type != 'number'">{{ collectFormatted(row, column) }}</span>
                        <span v-if="!isActiveEdit(row,index) && !column.html && column.type == 'number' && column.label != 'id'">
                            <vue-numeric currency="$" separator="," v-model="row[column.field]" read-only :minus="true" ></vue-numeric>
                        </span>
                        <span v-if="!isActiveEdit(row,index) && !column.html && column.type == 'number' && column.label == 'id'">
                            {{ collectFormatted(row, column) }}
                        </span>
						<span v-if="!isActiveEdit(row,index) && column.html" v-html="collect(row, column.field)">

						</span>
					</td>
                    <td v-if="actions != undefined && actions.length > 0" class="actions text-center" @click.prevent.stop="triggerActive(row, index)" >
                        <!-- If there are actions display an actions button -->
                        <!-- If there are actions display an actions button -->
                        <div >
                            <i class="icon ion-ios-more more" :class="{active: (active != null && active.index == index)}"></i>
                        </div>

                        <div class="actions-list" v-if="(active != null && active.index == index)">
                            <ul class="text-black" v-if="!active.editing">
                                <li v-for="action in actions" @click.prevent.stop="callAction(action,row,index)">
                                    <i class="icon" :class="action.icon"></i>{{ action.name }}
                                </li>
                            </ul>
                            <ul class="text-black" v-if="active.editing">
                                <li @click.prevent.stop="save(row,index)">
                                    <i class="icon ion-ios-download"></i>Save
                                </li>
                                <li @click.prevent.stop="closeActive()">
                                    <i class="icon ion-android-cancel"></i>Cancel
                                </li>
                            </ul>
                        </div>
                    </td>
				</slot>

			</tr>

		</tbody>
	</table>

	<div class="table-footer clearfix" v-if="paginate">
		<div class="datatable-length pull-left">
			<label>
          <span>{{rowsPerPageText}}</span>
          <span v-if="perPage" class="perpage-count">{{perPage}}</span>
          <select v-if="!perPage" class="browser-default" @change="onTableLength">
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="40">40</option>
            <option value="50" selected="selected">50</option>
          </select>
        </label>
		</div>
		<div class="pagination-controls pull-right">
			<a href="javascript:undefined" class="page-btn" @click.prevent.stop="previousPage" tabindex="0">
          <span class="chevron left"></span>
          <span>{{prevText}}</span>
        </a>
			<div class="info">{{paginatedInfo}}</div>
			<a href="javascript:undefined" class="page-btn" @click.prevent.stop="nextPage" tabindex="0">
          <span>{{nextText}}</span>
          <span class="chevron right"></span>
        </a>
		</div>
	</div>
</div>
</template>

<script>
import parse from 'date-fns/parse';
import format from 'date-fns/format';
import VueNumeric from 'vue-numeric';


export default {
	name: 'vue-good-table',
	props: {
		styleClass: {
			default: 'table table-bordered'
		},
		title: '',
		columns: {},
		rows: {},
        actions: {},
		onClick: {},
		perPage: {},
		sortable: {
			default: true
		},
		paginate: {
			default: false
		},
		lineNumbers: {
			default: false
		},
		defaultSortBy: {
			default: null
		},

		// search
		globalSearch: {
			default: false
		},
		searchTrigger: {
			default: null
		},
		externalSearchQuery: {
			default: null
		},

		// text options
		globalSearchPlaceholder: {
			default: 'Search Table'
		},
		nextText: {
			default: 'Next'
		},
		prevText: {
			default: 'Prev'
		},
		rowsPerPageText: {
			default: 'Rows per page:'
		},
        defaultFilters:{},
	},

    mounted(){
        this.$Progress.finish();

    },

    created(){
        if(this.defaultFilters){
            this.columnFilters = this.defaultFilters;
        }
    },

	data: () => ({
		currentPage: 1,
		currentPerPage: 50,
		sortColumn: -1,
		sortType: 'asc',
		globalSearchTerm: '',
		columnFilters: {},
		filteredRows: [],
		timer: null,
		forceSearch: false,
		sortChanged: false,
        active: null,
	}),

	methods: {
		nextPage() {
            this.active = null;
			if (this.currentPerPage == -1) return;
			if (this.processedRows.length > this.currentPerPage * this.currentPage)
				++this.currentPage;
		},

		previousPage() {
            this.active = null;
			if (this.currentPage > 1)
				--this.currentPage;
		},

		onTableLength(e) {
			this.currentPerPage = e.target.value;
		},

		sort(index) {
			if (!this.sortable)
				return;
			if (this.sortColumn === index) {
				this.sortType = this.sortType === 'asc' ? 'desc' : 'asc';
			} else {
				this.sortType = 'asc';
				this.sortColumn = index;
			}
			this.sortChanged = true;
		},

		click(row, index) {
			if (this.onClick)
				this.onClick(row, index);
		},

        // Some new functions
        triggerActive(row,index){
            if(this.active != null && this.active.row == row){
                this.active = null;
            }else{
                this.active = {row: row, index: index,editing:false};
            }
        },

        callAction(action, row, index){
            if(action.name === "Edit"){
                // Very special for edit
                this.active.editing = true;
            }else{
                // all other actions trigger active = null after emitting the callback
                this.active = null;
                this.$emit(action.callback,{row,index});
            }
        },

        save(row,index){
            this.$emit('save',{row,index});
            this.active = null;
        },

        isActive(row,index){
            if(this.active != null && index == this.active.index){
                return true;
            }else{
                return false;
            }
        },

        isActiveEdit(row,index){
            if(this.active != null && index == this.active.index && this.active.editing != null && this.active.editing == true){
                return true;
            }else{
                return false;
            }
        },

        closeActive(){
            this.active = null;
        },


        collectInput(row,column){
            //lets format the resultant data
			switch (column.type) {
				case 'decimal':
					return
				case 'percentage':
					return formatPercent(value);
				case 'date':
					return formatDate(value);
				default:
					return value;
			}
        },


		searchTable() {
			if (this.searchTrigger == 'enter') {
				this.forceSearch = true;
				this.sortChanged = true;
			}
		},

		// field can be:
		// 1. function
		// 2. regular property - ex: 'prop'
		// 3. nested property path - ex: 'nested.prop'
		collect(obj, field) {

			//utility function to get nested property
			function dig(obj, selector) {
				var result = obj;
				const splitter = selector.split('.');
				for (let i = 0; i < splitter.length; i++)
					if (typeof(result) === 'undefined')
						return undefined;
					else
						result = result[splitter[i]];
				return result;
			}

			if (typeof(field) === 'function')
				return field(obj);
			else if (typeof(field) === 'string')
				return dig(obj, field);
			else
				return undefined;
		},

		collectFormatted(obj, column) {
			//helper functions within collect
			function formatDecimal(v) {
				return parseFloat(Math.round(v * 100) / 100).toFixed(2);
			}

			function formatPercent(v) {
				return parseFloat(v * 100).toFixed(2) + '%';
			}

			function formatDate(v) {
				// convert to string
				v = v + '';

				// convert to date
				return format(parse(v, column.inputFormat), 'YYYY-MM-DD');
			}


			var value = this.collect(obj, column.field);

			if (!value) return '';
			//lets format the resultant data
			switch (column.type) {
				case 'decimal':
					return formatDecimal(value);
				case 'percentage':
					return formatPercent(value);
				case 'date':
					return formatDate(value);
				default:
					return value;
			}
		},


		// Get the necessary style-classes for the given column
		//--------------------------------------------------------
		columnHeaderClass(column, index) {
			var classString = '';
			if (this.sortable) {
				classString += 'sorting ';
			}
			if (index === this.sortColumn) {
				if (this.sortType === 'desc') {
					classString += 'sorting-desc ';
				} else {
					classString += 'sorting-asc ';
				}
			}
			classString += this.getDataStyle(index, 'th');
			return classString;
		},
		// given column index, we can figure out what style classes
		// to apply to this data
		//---------------------------------------------------------
		getDataStyle(index, type) {
			var classString = '';
			if (typeof type !== 'undefined' && this.columns[index].hasOwnProperty(type + 'Class')) {
				classString = this.columns[index][type + 'Class'];
			} else {
				switch (this.columns[index].type) {
					case 'number':
					case 'percentage':
					case 'decimal':
					case 'date':
						classString = 'right-align ';
						break;
					default:
						classString = 'left-align ';
						break;
				}
			}
			return classString;
		},

		//since vue doesn't detect property addition and deletion, we
		// need to create helper function to set property etc
		updateFilters(column, value) {
			const _this = this;
			if (this.timer) clearTimeout(this.timer);
			this.timer = setTimeout(function() {
				_this.$set(_this.columnFilters, column.field, value)
			}, 400);

		},

		//method to filter rows
		filterRows() {
			var computedRows = JSON.parse(JSON.stringify(this.rows));
			if (this.hasFilterRow) {
				for (var col of this.columns) {
					if (col.filterable && this.columnFilters[col.field]) {
						computedRows = computedRows.filter(row => {

							switch (col.type) {
								case 'number':
								case 'percentage':
								case 'decimal':
									//in case of numeric value we need to do an exact
									//match for now`
									return row[col.field] == this.columnFilters[col.field];
								default:
									//text value lets test starts with
									return (row[col.field]).toLowerCase().startsWith((this.columnFilters[col.field]).toLowerCase());
							}
						});
					}
				}
			}
			this.filteredRows = computedRows;
		},

		getCurrentIndex(index) {
			return (this.currentPage - 1) * this.currentPerPage + index + 1;
		},

	},

	watch: {
		columnFilters: {
			handler: function(newObj) {
				this.filterRows();
			},
			deep: true,
		},
		rows: {
			handler: function(newObj) {
				this.filterRows();
			},
			deep: true,
		},
		perPage() {
			if (this.perPage) {
				this.currentPerPage = this.perPage;
			} else {
				//reset to default
				this.currentPerPage = 10;
			}
		}

	},

	computed: {

		searchTerm() {
			return (this.externalSearchQuery != null) ? this.externalSearchQuery : this.globalSearchTerm;
		},

		//
		globalSearchAllowed() {
			if (this.globalSearch &&
				!!this.globalSearchTerm &&
				this.searchTrigger != 'enter') {
				return true;
			}

			if (this.externalSearchQuery != null &&
				this.searchTrigger != 'enter') {
				return true;
			}

			if (this.forceSearch) {
				this.forceSearch = false;
				return true;
			}

			return false;
		},

		// to create a filter row, we need to
		// make sure that there is atleast 1 column
		// that requires filtering
		hasFilterRow() {
			if (!this.globalSearch) {
				for (var col of this.columns) {
					if (col.filterable) {
						return true;
					}
				}
			}
			return false;
		},

		// this is done everytime sortColumn
		// or sort type changes
		//----------------------------------------
		processedRows() {
            this.active = null;
			var computedRows = this.filteredRows;

			// take care of the global filter here also
			if (this.globalSearchAllowed) {
				var filteredRows = [];
				for (var row of this.rows) {
					for (var col of this.columns) {
						if (String(this.collectFormatted(row, col)).toLowerCase()
							.includes(this.searchTerm.toLowerCase())) {
							filteredRows.push(row);
							break;
						}
					}
				}
				computedRows = filteredRows;
			}

			//taking care of sort here only if sort has changed
			if (this.sortable !== false &&

				// if search trigger is enter then we only sort
				// when enter is hit
				(this.searchTrigger != 'enter' || this.sortChanged)) {

				this.sortChanged = false;

				computedRows = computedRows.sort((x, y) => {
					if (!this.columns[this.sortColumn])
						return 0;

					const cook = (x) => {
						x = this.collect(x, this.columns[this.sortColumn].field);

						if (typeof(x) === 'string') {
							x = x.toLowerCase();
							if (this.columns[this.sortColumn].type == 'number')
								x = x.indexOf('.') >= 0 ? parseFloat(x) : parseInt(x);
						}

						//take care of dates too.
						if (this.columns[this.sortColumn].type === 'date') {
							x = parse(x + '', this.columns[this.sortColumn].inputFormat);
						}

						return x;
					}

					x = cook(x);
					y = cook(y);

					return (x < y ? -1 : (x > y ? 1 : 0)) * (this.sortType === 'desc' ? -1 : 1);
				})
			}

			// if the filtering is event based, we need to maintain filter
			// rows
			if (this.searchTrigger == 'enter') {
				this.filteredRows = computedRows;
			}

			return computedRows;
		},

		paginated() {
			var paginatedRows = this.processedRows;

			if (this.paginate) {
				var pageStart = (this.currentPage - 1) * this.currentPerPage;

				// in case of filtering we might be on a page that is
				// not relevant anymore
				// also, if setting to all, current page will not be valid
				if (pageStart >= this.processedRows.length ||
					this.currentPerPage == -1) {
					this.currentPage = 1;
					pageStart = 0;
				}

				//calculate page end now
				var pageEnd = paginatedRows.length + 1;

				//if the setting is set to 'all'
				if (this.currentPerPage != -1) {
					pageEnd = this.currentPage * this.currentPerPage;
				}

				paginatedRows = paginatedRows.slice(pageStart, pageEnd);
			}
			return paginatedRows;
		},

		paginatedInfo() {
			var infoStr = '';
			infoStr += (this.currentPage - 1) * this.currentPerPage ? (this.currentPage - 1) * this.currentPerPage : 1;
			infoStr += ' - ';
			infoStr += Math.min(this.processedRows.length, this.currentPerPage * this.currentPage);
			infoStr += ' of ';
			infoStr += this.processedRows.length;
			if (this.currentPerPage == -1) {
				return '1 - ' + this.processedRows.length + ' of ' + this.processedRows.length;
			}
			return infoStr;
		},
	},

	mounted() {
		this.filteredRows = JSON.parse(JSON.stringify(this.rows));
		if (this.perPage) {
			this.currentPerPage = this.perPage;
		}

		//take care of default sort on mount
		if (this.defaultSortBy) {
			for (let [index, col] of this.columns.entries()) {
				if (col.field === this.defaultSortBy.field) {
					this.sortColumn = index;
					this.sortType = this.defaultSortBy.type || 'asc';
					this.sortChanged = true;
					break;
				}
			}
		}
	},
    components: {
        VueNumeric
    }
}
</script>

<style lang="css" scoped>

/* Utility styles
************************************************/
.right-align{
  text-align: right;
}

.left-align{
  text-align: left;
}

.pull-left{
  float:  left !important;
}

.pull-right{
  float:  right !important;
}

.clearfix::after {
  display: block;
  content: "";
  clear: both;
}

/* Table specific styles
************************************************/

  table{
    border-collapse: collapse;
    background-color: transparent;
    margin-bottom:  20px;
  }
  .table{
    width: 100%;
    max-width: 100%;
  }

  .table.table-striped tbody tr:nth-of-type(odd) {
      background-color: rgba(35,41,53,.05);
  }

  .table.table-bordered td, .table-bordered th {
      border: 1px solid #333;;
      border-color: inherit;
  }

  .table td, .table th:not(.line-numbers) {
    padding: .75rem 1.5rem .75rem .75rem;
    vertical-align: top;
    border-top: 1px solid;
    border-color: inherit;
  }

  .table.condensed td, .table.condensed th {
    padding: .4rem .4rem .4rem .4rem;
  }

  .table thead th, .table.condensed thead th {
    vertical-align: bottom;
    border-bottom:  2px solid #333;
    padding-right: 1.5rem;
    background-color: rgba(35,41,53,0.03);
  }

  tr.clickable {
    cursor: pointer;
  }

  .table input{
    /*display: block;
    width: calc(100% - 24px);
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(35,41,53,.075);
    box-shadow: inset 0 1px 1px rgba(35,41,53,.075);
    -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;*/
  }

  table th.sorting-asc,
  table th.sorting-desc {
    color: #fff;
    position: relative;
  }

  table th.sorting:after,
  table th.sorting-asc:after  {
    font-family: 'Material Icons';
    position:  absolute;
    height:  0px;
    width:  0px;
    content: '';
    display: none;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid rgba(0, 0, 0, 0.66);
    margin-top:  6px;
    margin-left:  5px;
  }

  table th.sorting:hover:after{
    display: inline-block;
    border-bottom-color: rgba(35,41,53,0.25);
  }

  table th.sorting-asc:after,
  table th.sorting-desc:after {
    display: inline-block;
  }

  table th.sorting-desc:after {
    /*border-top:  6px solid rgba(0, 0, 0, 0.66);*/
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: none;
    margin-top:  8px;
  }



/* Table footer specific styles
************************************************/

  .table-footer{
    /* background-color: rgba(35,41,53, 0.03); */
    /*background-color: rgba(35,41,53,0.05);*/
    /*border: 1px solid #333;*/
    margin-bottom:  2rem;
    margin-top:  -20px;
    padding:  1rem;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    font-size: 14px;
    color:#fff;
    color:  rgba(0, 0, 0, 0.44);
  }

  .table-footer>div{
    display: inline-block;
  }

  .pagination-controls>*{
    display: inline-block;
  }

  .pagination-controls a{
    text-decoration: none;
    color: rgba(0, 0, 0, 0.66);
    font-size: 14px;
    font-weight: 600;
    opacity: 0.8;
  }

  .pagination-controls a:hover{
    opacity: 1;
  }

  .pagination-controls a span{
    display: inline-block;
    vertical-align: middle;
  }

  .pagination-controls .info{
    margin:  0px 15px;
    font-size: 13px;
    font-weight: bold;
    /*color:  rgba(0, 0, 0, 0.40);*/
  }

  .pagination-controls a .chevron{
    width:  24px;
    height:  24px;
    border-radius: 15%;
    /* border:  1px solid rgba(35,41,53,0.2);
    background-color: #fff; */
    position:  relative;
    margin:  0px 8px;
  }

  .pagination-controls .chevron::after{
    content:  '';
    position:  absolute;
    display:  block;
    left:  50%;
    top:  50%;
    margin-top:  -6px;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
  }

  .pagination-controls .chevron.left::after{
    border-right:  6px solid rgba(0, 0, 0, 0.66);
    margin-left:  -3px;
  }

  .pagination-controls .chevron.right::after{
    border-left:  6px solid rgba(0, 0, 0, 0.66);
    margin-left:  -3px;
  }

  .table-footer select {
    display: inline-block;
    background-color: transparent;
    width: auto;
    padding: 0;
    border: 0;
    border-radius: 0;
    height: auto;
    font-size: 14px;
    margin-left: 8px;
    color:  rgba(0, 0, 0, 0.55);
    font-weight: bold
  }

  .table-footer .perpage-count{
    /*color:  rgba(0, 0, 0, 0.55);*/
    font-weight: bold;
  }

  @media only screen and (max-width: 750px) {
    /* on small screens hide the info */
    .pagination-controls .info{
      display:  none;
    }
  }

  /* Global Search
  **********************************************/
  .global-search{
    position:  relative;
    padding-left: 40px;
  }
  .global-search-icon{
    position:  absolute;
    left:  0px;
    max-width:  32px;
  }
  .global-search-icon > img{
    max-width:  100%;
    margin-top:  8px;
    opacity: 0.5;
  }
  table .global-search-input{
   width:  calc(100% - 30px);
  }

  /* Line numbers
  **********************************************/
  table th.line-numbers, .table.condensed th.line-numbers{
    /*background-color: rgba(35,41,53,0.05);*/
    padding-left:  3px;
    padding-right:  3px;
    word-wrap: break-word;
    width: 45px;
    text-align: center;
  }

</style>
