<template>
    <div>
        <section class="section section-welcome-message">
            <div class="container">
                <div class="row">
                    <div class="col-xs-12 col-sm-9">
                        <h1 class="welcome-message">New Parade</h1>
                    </div>
                    <div class="col-xs-12 col-sm-3">
                        <router-link to="/events">
                            <button>Back</button>
                        </router-link>
                    </div>
                </div>
            </div>
        </section>

        <section class="section section-new-group">
            <div class="container">
                <div class="row">
                    <div class="col-xs-12 col-md-12">
                        <div class="form-NewGroup">
                            <!-- Standard field input -->
                            <fieldset class="field-textinput">
                                <label for="name-group">
                                    <span>Parade Name</span>
                                    <hr class="bg-white">
                                </label>
                                <input type="text" name="name-group" v-model="parade.title" placeholder="Name">
                            </fieldset>

                            <fieldset class="field-textinput">
                                <label for="name-group">
                                    <span>Parade Date</span>
                                    <hr class="bg-white">
                                </label>
                                <input type="date" name="name-group" v-model="parade.date" placeholder="Name">
                            </fieldset>

                            <fieldset class="field-textinput">
                                <label for="name-group">
                                    <span>Parade Tax Date</span>
                                    <hr class="bg-white">
                                </label>
                                <input type="date" name="name-group" v-model="parade.tax_date" placeholder="Name">
                            </fieldset>

                            <!-- Textarea -->
                            <fieldset class="field-textarea">
                                <label for="description-group">
                                    <span>Parade Description</span>
                                    <hr class="bg-white">
                                </label>
                                <textarea name="description-group" id="description-group" cols="30" rows="8"  v-model="parade.description" placeholder="Description"></textarea>
                            </fieldset>
                            <br><br>

                            <fieldset class="field-textarea">
                                <button class="button-link"  @click="save">Save</button>
                            </fieldset>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    data() {
        return {
            parade:{
                id: null,
                title: null,
                description: null,
                date:null,
                tax_date:null,
            },
            viewing: true,
        }
    },
    created() {},
    methods: {
        edit(){
            this.viewing = false;
        },
        save() {

            // console.log("trying to save");
            this.$Progress.start();
            // save the new participant
			axios.post('/api/events/register', {
                title: this.parade.title,
                description: this.parade.description,
                date: this.parade.date,
                tax_date: this.parade.tax_date,
			})
			.then((response) => {
				// console.log("----------------");
				// console.log(response);

                this.$Progress.finish()
                this.$toasted.success("Success! Event '" + this.parade.title + "' saved.").goAway(6000);

                this.$router.push({
                    path: '/events/',
                })

			})
			.catch((error) => {
                this.$Progress.fail()
				if(error.response) {
					// The request was made and the server responded with a status code
					// that falls out of the range of 2xx
                    if(error.response.status == 422){

                        // validation failed
                        var obj = error.response.data;
                        // console.log(obj);
                        for (var key in obj) {
                            let displayKey = key.charAt(0).toUpperCase() + key.slice(1);
                            displayKey = displayKey.replace(/_/g, " ");
                            // console.log(key); // key1 and etc...
                            // console.log(obj[key]); // val1 and etc...
                            this.$toasted.error(displayKey + " : " + obj[key]);
                        }

                    }else if(error.response.status == 405){

                    }
					// console.log(error.response.data);
					// console.log(error.response.status);
					// console.log(error.response.headers);
				} else if (error.request) {
					// The request was made but no response was received
					// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
					// http.ClientRequest in node.js
					// console.log(error.request);
				} else {
					// Something happened in setting up the request that triggered an Error
					// console.log('Error', error.message);
				}
			})
        },
    }
}
</script>
